.BoxGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);

  &:not(.disable) {
    grid-gap: 1rem;
    justify-content: flex-end;
  }
  grid-gap: 0.75rem;
  justify-content: flex-start;
}

.teacherProperties {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.GridRows {
  color: $color-black-light;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  i {
    color: $color-green1;
  }
  &:not(.disable) {
    font-size: 1.3rem;
    justify-content: end;
    flex-direction: row-reverse;
  }
  font-size: 1.5rem;
  justify-content: start;
}
