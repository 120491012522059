.page-number{
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    padding:0 5px;
    color: $color-grey-dark;
    border: 1px solid $color-grey-light-1;
    border-radius: 5rem;
    background-color: white;
    @include respond(tab-port){
        display: none;
     };
     
    span{
        font-size: 1.5rem;
        
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        height: 3rem;
        
        &:last-child,
        &:first-child{
            
            border-radius: 50%;
            border: 1px solid $color-grey-light-1;
            margin: 2px;
            

        }
    }
}