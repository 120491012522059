.BtnPrimary {
  border: 3px solid $color-green-dark-2;
  padding: 0.8rem 2.3rem;
  border-radius: 2rem;
  font-size: 1.6rem;
  text-align: center;
  background-color: transparent;
  color: $color-green-dark-2;
  transition: background-color ease-out 0.1s, border 0.1s linear 0.3s;
  cursor: pointer;
  a {
    color: $color-green-dark-2;
    text-decoration: none;
  }
  outline: none;

  &:focus {
    outline: none;
    border: 3px solid $color-green-dark-2;
  }
  &:not(.disable):hover {
    background-color: $color-green-dark-2;
    border: 3px solid $color-green-dark-2;
    color: $color-white;
  }
}
.BtnSecondary {
  border: none;
  padding: 1rem 2rem;
  border-radius: 3rem;
  font-size: 1.5rem;
  text-align: center;
  background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
  color: $color-white;
  cursor: pointer;
  position: relative;
  a {
    color: $color-white;
    text-decoration: none;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }
  &::after {
    background-color: rgb(214, 204, 194);
  }
  &:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }

  &:focus {
    outline: none;
  }
  @include respond(phone) {
    padding: 0.9rem 1.3rem;
    font-size: 1.6rem;
  }
  @include respond(smallest-phone) {
    padding: 0.8rem 0.5rem;
  }
}
.BtnSecondary1 {
  border: none;
  padding: 1rem 2rem;
  border-radius: 3rem;
  font-size: 1.5rem;
  text-align: center;

  //background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
  color: $color-white;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @include respond(phone) {
    padding: 0.9rem 1.3rem;
    font-size: 1.6rem;
  }
  @include respond(smallest-phone) {
    padding: 0.8rem 0.5rem;
  }
}
.BtnSecondarybig {
  position: relative;
  border: none;
  text-align: center;
  background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
  color: $color-white;
  border-radius: 3rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &::after {
    background-color: rgb(214, 204, 194);
  }

  &:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }

  @include respond(smallest-phone) {
    // padding: 0.8rem 0.5rem;
  }
}
.BtnSubmitWhite {
  border: none;
  padding: 0.8rem 2.3rem;
  border-radius: 2rem;
  font-size: 1.6rem;
  text-align: center;
  background-color: $color-white;
  color: $color-green-dark-2;
  transition: background-color ease-out 0.1s, border 0.1s linear 0.3s;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: $color-white;
  }
  &:hover a {
    color: $color-green-dark-2;
  }
  & a {
    color: $color-green-dark-2;
  }
}

.BtnDynamicGreen {
  border: none;
  padding: 1rem 2.5rem;
  border-radius: 2rem;
  font-size: 2rem;
  text-align: center;
  background-color: $color-green-dark;
  color: $color-white;
  //marginRight: "3rem",
  cursor: pointer;
}
.BtnTransparent {
  background-color: rgba($color-white, 0.8);
  padding: 0.5rem;
  border: none;
  border-radius: 2rem;
  font-size: 1.1rem;
  text-align: center;

  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.BtnOrangeTransparent {
  background-color: transparent;
  border: 1px solid $color-orange-dark-1;
  border-radius: 3rem;
  color: $color-orange-dark-1;
  cursor: pointer;
}
@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
