/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  position: relative;
}
.accordion__sectionDis {
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: not-allowed;
}
/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: transparent;
  color: $color-black-dark;
  cursor: pointer;
  padding: 1.9rem 2rem 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  position: relative;
  //   border-bottom: 1px solid $color-grey-dark-1;
  outline: none;
  font-family: 'Vazir', sans-serif;
  transition: background-color 0.6s ease;
  // .fas {
  //   font-size: 1.5rem;
  //   position: absolute;
  //   left: 6rem;
  // }
  .fa-check-circle {
    color: $color-green-dark;
    position: absolute;
    left: 60px;
  }
  .fa-lock {
    color: $color-grey-light-5;
  }
  &__Circle {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 10rem;
    position: absolute;
    left: 4rem;
  }
}
.accordioninfo {
  background-color: transparent;
  color: $color-black-dark;
  cursor: pointer;
  padding: 1.9rem 0.5rem 1.5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  position: relative;
  //   border-bottom: 1px solid $color-grey-dark-1;
  outline: none;
  font-family: 'Vazir', sans-serif;
  transition: background-color 0.6s ease;
  .fas {
    font-size: 1.5rem;
    position: absolute;
    left: 6rem;
  }
  .fa-check-circle {
    color: $color-green-dark;
  }
  .fa-lock {
    color: $color-grey-light-5;
  }
  &__Circle {
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-right: 10rem;
    position: absolute;
    left: 4rem;
  }
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: transparent;
  color: $color-orange-dark-1;
}

/* Style the accordion content title */
.accordion__title {
  font-family: 'Vazir', sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  text-align: right;
  white-space: pre-wrap;
  width: 200px;
}
.accordion__title.accordion__title1{
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  direction: rtl;
  width: 159px;

}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-right: auto;
  transition: transform 0.6s ease;
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(180deg);
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
  &::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ffffff;
border: 1px solid #c7c7c7;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(#8b8b8b, 0.26);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(#838385, 0.26);
  }
  p {
    color: #5f5f5f;
    font-size: 1.2rem;
    margin-top: 0.4rem;
    text-align: right;
    margin-bottom: 1.2rem;
    font-weight: 500;
    margin-right: 26px;
  }
}

/* Style the accordion content text */
.accordion__text {
  font-family: 'Vazir', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  margin-right: 1rem;
  text-align: right;
}

.accordion__LineBotten {
  width: 87%;
  height: 1px;
  background-color: #70707021;
  margin: 0 3.2rem 0 2rem;
}
.accordion__contentBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 0 0;

  .fas {
    font-size: 1.5rem;
    margin-left: 5.6rem;
  }
  .fa-check-circle {
    color: $color-green-dark;
  }
  .fa-lock {
    color: $color-grey-light-5;
  }
}
.accordion__contentTop {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .far {
    margin-right: 2.21rem;
    font-size: 1.5rem;
  }
}
.accordion__Subtitle {
  color: $color-black-dark;
  font-size: 1.2rem;
  margin-top: 0.4rem;
  text-align: right;
}
.corsecontentbody{
  max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    /* Track */
    &::-webkit-scrollbar-track {
      background: #ffffff;
    }
  
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(#8b8b8b, 0.26);
    }
  
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(#838385, 0.26);
    }
}