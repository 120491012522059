.final-cost-table {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-content: center;
  padding: 2rem;
  grid-gap: 2rem;
  border-bottom: 1px solid $color-grey-light-3;

  p,
  span {
    font-size: 1.3rem;
    letter-spacing: 1px;
  }

  span {
    direction: rtl;
    margin: 0 1px;
  }
}

.final-cost-price {
}
.final-cost-tips {
  direction: rtl;
}
.final-cost-pay {
  display: flex;
  padding: 1rem 0;
  align-items: center;
  justify-content: center;
  &-price {
    color: $color-green-dark;
  }
  p {
    font-size: 1.4rem;
    padding: 0 3.5rem;
    display: flex;
    flex-direction: row-reverse;
  }
}
.Card-tip-form {
  @include center;
  flex-direction: row-reverse;
  padding: 2rem 1rem;
  &-input {
    background-color: none;
    border: 1px solid $color-grey-light-3;
    outline: none;
    border-radius: 1rem;
    padding: 0.8rem;
    margin-left: 0.5rem;
    &:focus {
      outline: none;
    }
  }
  &-btn {
    white-space: nowrap;
    padding: 0.5rem 0.8rem;
  }
}
