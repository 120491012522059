.codeeditorBox {
  width: 20rem;
  height: 10rem;
  overflow-y: auto;
  &__activbtn {
    background-color: #272822;
    color: #fcca46;
    border: none;
    border-bottom: 1px solid #fcca46;
    // border-right: 1px solid #fcca46;
    padding: 0.5rem 1rem;
    border-top-left-radius: 0.45rem;
    border-top-right-radius: 0.45rem;
    font-size: 1.5rem;
    &:focus {
      outline: none;
    }
  }
  &__disactivbtn {
    color: $color-white;
    background-color: transparent;
    border: none;
    // border-right: 1px solid $color-black;
    // border-left: 1px solid #fcca46;
    font-size: 1.5rem;
    padding: 0.15rem 1rem;
  }
  &__BtnBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #272822;
    border-bottom: 1px solid #fcca46;
    border-bottom: 1px solid #fcca46;
  }
  &__BtnBox1 {
    display: flex;
    align-items: center;
    width: 35vw;
    justify-content: space-between;
    background-color: #272822;
    border-bottom: 1px solid #fcca46;
    border-bottom: 1px solid #fcca46;
  }
  &__btnDon {
    cursor: pointer;
    border-radius: 2rem;
    color: $color-white;
    display: flex;
    align-items: center;
    &:focus {
      outline: none;
    }
    margin-right: 1rem;
  }
  &__fulScreen {
    width: 2rem;
    height: 2rem;
    border: 1px dashed #fcca46;
    background-color: transparent;
    cursor: pointer;
    margin-right: 1rem;
  }
}
