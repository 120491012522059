.property{
    padding: 3rem 13rem ;
    display: flex;
    align-items: center;
    flex-direction: column;
        
    @include respond(tab-land){
      
        padding: 3rem 5rem ;
        
      };
      @include respond(phone){
      
        padding: 3rem 1rem ;
        
      };

    &> :nth-child(odd) {
        flex-direction: row-reverse;
        align-self: flex-end;

    }

    &> :nth-child(even) {
        flex-direction: row;
        align-self: flex-start;

    }
    &__box{
        display: flex;
    justify-content: flex-start;
    align-items: center;
    

        &-pic{
            @include center; 
            border-radius: 50%;
            width: 14rem;
            height: 14rem;
            &--1{
                background-color: #FDE6E5;
            }
            &--2 {
                background-color:#E7F1F4;
            }

            &--3 {
                background-color: #F4F9ED;
            }

            &--4 {
                background-color: #FFF5EE;
                img{
                    transform: translateX(1rem);
                }
            }
            &--5 {
                background-color: #FDE6E5;
            }

          img{
            width: 11.2rem;
          }
        }
      
        &-content{
            flex-basis: 20rem;
            padding: 1.8rem;
            margin: 1.8rem;

            @include respond(smallest-phone){
          
                flex-basis: 22rem;
      
      
                   
                         };
            h4{
                margin-bottom: 0.8rem;
            }
            p{
                font-size: 1rem;
                color: $color-grey-dark;
                
            }
            &--1{
                text-align: end;
                @include respond(phone){
          
                    padding-right: 0.3rem;
                   
          
                       
                             };
            }
            &--2{
                text-align: start;
                @include respond(phone){
          
                    padding-left: 0.3rem;
                   
          
                       
                             };
            }
            &--3{
                text-align: end;
                @include respond(phone){
          
                    padding-right: 0.3rem;
                   
          
                       
                             };
            }
            &--4{
                text-align: start;
                @include respond(phone){
          
                    padding-left: 0.3rem;
                   
          
                       
                             };
            }
            &--5{
                text-align: end;
                @include respond(phone){
          
                    padding-right: 0.3rem;
                   
          
                       
                             };
            }
        }
    
    }

}