.PhoneCourseInfo__FixBtn {
  position: fixed;
  top: 47.5%;
  right: 39.5%;
  z-index: 1000;

  @include respond(phone) {
    top: 47.5%;
    right: 39.5%;
  }
  @include respond(small-phone) {
    top: 42.3%;
    right: 39.5%;
  }
  @include respond(smallest-phone) {
    top: 56%;
    right: 42.5%;
  }
}
