.content__box {
  display: flex;
  align-content: center;
  justify-content: space-between;

  flex-direction: column;
  width: 150px;
}
.img__back {
  border-radius: 50%;
  @include center;
  margin: 0 2rem;
  text-align: center;
  background-color: #192f3d;
  padding: 2rem;
  width: 10rem;
  height: 10rem;
  margin-left: 3rem;
  @include respond(small-phone) {
    border-radius: 50%;
    @include center;
    margin: 0 auto;
    text-align: center;
    background-color: #192f3d;
    padding: 2rem;
    width: 10rem;
    height: 10rem;
  }
}
.img__back-2 {
  border-radius: 50%;
  @include center;
  margin: 0 2rem;
  text-align: center;
  background-color: #192f3d;
  padding: 2rem 2rem 2rem 3.5rem;
  width: 10rem;
  height: 10rem;
  margin-left: 3rem;
  @include respond(small-phone) {
    border-radius: 50%;
    @include center;
    margin: 0 auto;
    text-align: center;
    background-color: #192f3d;
    padding: 2rem;
    width: 10rem;
    height: 10rem;
  }
}
.content__box-0 {
  @include respond(tab-port) {
    margin-left: 1rem;
  }
  @include respond(big-phone) {
    grid-column: 2/2;
  }
  @include respond(phone) {
    grid-column: 2/2;
  }
  // @include respond(small-phone) {
  //   grid-column: 2/2;
  // }

  // @include respond(smallest-phone) {
  //   grid-column: 2/2;
  // }
}
.content__box-1 {
  grid-column: 3/4;
  @include respond(big-phone) {
    grid-column: 4/5;
  }
  @include respond(phone) {
    grid-column: 4/5;
  }
}
.content__box-2 {
  grid-column: 5/-1;
  @include respond(big-phone) {
    grid-column: 2/3;
  }
  @include respond(phone) {
    grid-column: 2/3;
  }
}
.content__box-3 {
  grid-column: 2/3;
  @include respond(big-phone) {
    margin-top: 0.8rem;
    grid-column: 4/5;
  }
  @include respond(phone) {
    grid-column: 4/5;
  }
}
.content__box-4 {
  grid-column: 4/5;
  @include respond(big-phone) {
    grid-column: 3/4;
  }
  @include respond(phone) {
    grid-column: 3/4;
  }
}

.img_size {
  width: 7rem;
  height: auto;
}

.text__titr {
  color: $color-black-light;
  font-size: 1.6rem;
  white-space: nowrap;
  text-align: center;
  margin: 10px 0;
  @include respond(big-phone) {
    margin: 0.8rem 0;
  }
  @include respond(phone) {
    margin: 0.8rem 0;
  }
}

.text__description {
  color: $color-grey-dark;
  font-size: 1.2rem;
  text-align: center;
  width: 17.4rem;
  padding-right: 1rem;
  @include respond(big-phone) {
    font-size: 1rem;
  }
  @include respond(phone) {
    font-size: 1rem;
  }
  @include respond(small-phone) {
    font-size: 1rem;
    margin: 0 auto;
  }
}
