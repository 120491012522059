.UserCommentsectionBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  animation: opacity 0.7s ease-in-out;
  animation-iteration-count: 1;
}
.AdminCommentsection {
  padding: 10px;
  border-radius: 5px;
  margin-right: 91px;
  border: 1px solid #eaeaea;
  margin-top: 17px;
}
.UserCommentsectionHeadrBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  & > :first-child {
    margin-left: 1rem;
  }
}
.UserCommentsectionHeadrIco {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.UserCommentsectionHeadrTitle {
  font-size: 1.27rem;
  margin-right: 0.5rem;
}
.UserCommentsectionHeadrTime {
  color: $color-grey-dark;
}
.UserCommentsectionContent {
  font-size: 1.27rem;
  font-weight: normal;
}
.UserCommentsectionContentBox {
  text-align: end;
  padding: 1.5rem 2rem;
  line-height: 1.8;
}
.DeleteModal__back {
  background-color: rgba(#a09c9c, 0.2);
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999999999999999999999999;
  animation: opacity 0.7s ease-in-out;
  animation-iteration-count: 1;
  .DeleteModal__container {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    position: relative;
  }
  h3 {
    font-size: 18px;
    margin: 20px 0;
  }
  .DeleteModal__btn {
    border: 2px solid $color-orange-dark-1;
    padding: 10px;
    border-radius: 10px;
    background-color: #ffffff;
    width: 50px;
    text-align: center;
    margin: 0 10px;
    &:focus {
      outline: none;
    }
  }
  .DeleteModal__btn--yes {
    background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
    color: #ffffff;
    border: none;
  }
  .DeleteModal__close {
    top: 10px;
    left: 10px;
    position: absolute;
  }
}
.reply__icon {
  display: flex;
  align-items: center;
  border: 1px solid #43aa8b;
  border-radius: 2px;
  font-size: 12px;
  color: #317a64;
  padding: 3px 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: #43aa8b;
    color: #ffffff;
    i {
      color: #ffffff;
    }
  }
  i {
    font-size: 12px;
    color: #317a64;
    margin-left: 5px;
  }
}

.reply__box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  direction: rtl;
}
.replyform {
  animation: opacity 0.5s ease-in-out;
  animation-iteration-count: 1;
  overflow: hidden;
  padding: 20px;
  &__btn {
    padding: 5px 15px;
  }
  &__textarea {
    width: 100%;
    height: 100%;
    resize: none;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    text-align: right;
    direction: rtl;
    font-family: "Samim", sans-serif !important;
    padding: 8px;
    &:focus {
      outline: none;
      border: 1px solid #43aa8b;
    }
  }
}
.EditReplyForm__display {
  margin-top: 10px;
  padding-right: 15%;
 
  .EditReplyForm__form--textarea {
    width: 100%;
    height: 100%;
    resize: none;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid #eaeaea;
    text-align: right;
    direction: rtl;
    font-family: "Samim", sans-serif !important;
    padding: 8px;
    &:focus {
      outline: none;
      border: 1px solid #43aa8b;
    }
  }
}
.EditReplyFormBtn {
  padding: 5px 15px;
  margin-right: 5px;
  width: 120px;
  margin-top: 10px;
  &:focus {
    outline: none;
  }
}
.EditReplyFormBtn-pri {

}
.EditReplyFormBtn-sec{
  border: 1px solid #fe7f2d;
  background-color: #ffffff;
  color: #fe7f2d;
  border-radius: 30px;
  transition: all 0.5 ease-in-out;
  cursor: pointer;
  &:hover{
    color: #ffffff;
    background-color: #fe7f2d;
  }
}
.Reply__toggle {
  animation: opacity 0.7s ease-in-out;
  animation-iteration-count: 1;
}
@keyframes height {
  from {
    max-height: 0;
  }
  to {
    max-height: max-content;
  }
}
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
