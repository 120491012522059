.courselist-sec {
  // transition: all 3s cubic-bezier(0.17, 0.67, 0.92, 0.01);
  position: relative;
  padding-bottom: 10rem;
  padding-top: 12rem;
 
  background-image: url('../../img/courselist/Subtraction\ 2.png');
  background-size: cover;
  @include respond(tab-land) {
    padding-top: 15rem;
  
  }
  @include respond(tab-port) {
    padding-top: 10rem;
 
  }
  @include respond(big-phone) {
 
  }
  @include respond(phone) {
    background-position: center;
 
  }
  .courselist {
    position: relative;
    display: flex;
    // &-sidebar {
    //   width: 25%;
    //   margin-left: 17px;

    //   @include respond(tab-port) {
    //     display: none;
    //   }
    // }
    &-courses {
      width: 100%;
      @include respond(tab-port) {
        width: 100%;
      }
    }
  }
}
.CourseListSec__pageination {
  text-align: center;
  margin: 2.5rem auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FilterNav {
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  background-color: #cbd0d3;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  border-radius: 2rem;
  z-index: 150;

  &__item {
    margin: 1rem 0;
    padding: 0 2rem;
    &:not(:last-child) {
      border-left: 2px solid #fff;
    }
  }
}
@keyframes movetop {
  0% {
    top: 70px;
  }
  20% {
    top: 75px;
  }
  30% {
    top: 80px;
  }
  50% {
    top: 85px;
  }
  75% {
    top: 90px;
  }
  100% {
    top: 100px;
  }
}

.fix {
  position: fixed;
  right: 103px;
  animation: movetop 0.2s ease-in-out;
  top: 100px;
  width: 21.5% !important;
  @include respond(tab-land) {
    right: 20px; //1rem=9px
    width: 24% !important;
  }
}

@include respond(tab-port) {
  display: none;
}
.nav-fix {
  position: fixed;
  top: 9rem;
  left: 0;
  transition: all 0.6s cubic-bezier(0.17, 0.67, 0.92, 0.01);
  background-color: #4e4e4e29;
  text-align: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  width: 100%;
  padding-right: 41rem;
  border-top: 1px solid black;
  border-radius: 0px;
  @include respond(tab-land) {
    top: 70px;
  }
  @include respond(tab-port) {
    padding-right: 0;
  }
}
.course__search {
  height: max-content;
  display: none;
  background-color: transparent;
  color: $color-green-dark-2;
  outline: none;
  border: none;
  font-size: 6rem;
  &:focus {
    outline: none;
    border: none;
  }
  @include respond(tab-port) {
    display: block;
    position: fixed;
    bottom: 11px;
    z-index: 1000;
  }
}
.search-active {
  display: block;
  position: fixed;
  bottom: 30px;
  left: 20px;
  z-index: 1000;
}
