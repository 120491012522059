.CommentRateBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @include respond(tab-land) {
    flex-direction: column;
    justify-content: center;
    padding-right: 0;

  }


}

.CommentRateBoxCol1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include respond(tab-land) {
    margin-bottom: 10px;
 
   }
}
.CommentRateTitle {
  font-size: 1.5rem;
  font-weight: 100;
  margin-right: -5rem;
}
.CommentRateContentBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-top: 2rem;
  & > :not(:first-child) {
    margin-right: 3rem;
  }
}
.CommentRateSatrBox {
  .MuiRating-root{
    font-size: 4.65rem;
    direction: rtl;
  }
}

.CommentRateNumberBox {
  display: flex;
  flex-direction: column;
}
.CommentRateNumberTop,
.CommentRateNumberDown {
  font-size: 1.2rem;
 
}
.CommentLineMiddel {
  width: 2px;
  height: 14rem;
  background-color: $color-grey-dark-1;
  opacity: 0.5;
  margin: 0 1.5rem;
  @include respond(tab-land) {
   display: none;

  }
}
.CommentRatebyline {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 19px;
  & > :not(:last-child) {
    margin-bottom: 1.65rem;
  }
}
.CommentRatebylineBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  & > :not(:first-child) {
    margin-right: 1rem;
  }
}
.CommentRatebylinetxt {
  font-size: 1.2rem;
  width: 83px;
  text-align: right;
}
.CommentRatebylineline {
  height: 2px;
  width: 18rem;
  background: linear-gradient(
    90deg,
    rgba($color-grey-dark-1, 0.5) 0%,
    rgba($color-grey-dark-1, 0.5) 60%,
    rgba($color-orange-dark-1, 1) 60%,
    rgba($color-orange-dark-1, 1) 100%
  );
}
.CommentRatebylineline1 {
  height: 2px;
  width: 18rem;
  background: linear-gradient(
    90deg,
    rgba($color-grey-dark-1, 0.5) 0%,
    rgba($color-grey-dark-1, 0.5) 40%,
    rgba($color-orange-dark-1, 1) 40%,
    rgba($color-orange-dark-1, 1) 100%
  );
}
.CommentRatebylineline2 {
  height: 2px;
  width: 18rem;
  background: linear-gradient(
    90deg,
    rgba($color-grey-dark-1, 0.5) 0%,
    rgba($color-grey-dark-1, 0.5) 20%,
    rgba($color-orange-dark-1, 1) 20%,
    rgba($color-orange-dark-1, 1) 100%
  );
}
.CommentRatebylinestrongtxt {
  color: $color-grey-dark;
  font-size: 1.1rem;
}
