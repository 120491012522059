.InfoSideBarPriseBox {
  border: 1px solid $color-grey-light-3;
  width: 100%;
  margin-top: 1rem;
  padding-bottom: 0.8rem;
  overflow: hidden;
  margin-left: -13px;
  @include respond(phone) {
    margin-top: 1rem;
    width: 22rem;
  }
  @include respond(big-desktop) {
    margin-left: -2px;

  }
}
.InfoSideBarPriseTable {
  width: inherit;
  & > :first-child {
    text-align: center;
  }
  & > :last-child {
    text-align: center;
  }
}

.priceHeader__line {
  border-bottom: 2px solid "red";
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  text-align: center;
  p {
    font-size: 1.5rem;
    color: $color-black-dark;
    font-weight: 400;
    margin: 0 auto;
    margin-bottom: 0.25rem;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
}
