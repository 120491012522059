.latestHome-btn {
  padding: 5rem 32rem 5rem 32rem;
  margin: 0 auto;
  display: table;
  // width: 84%;
  border-radius: 8rem !important;
  border: none;
  margin-bottom: 10rem;
  font-family: "Samim", sans-serif;
  font-size: 2.4rem;
  transition: all ease-in-out;

  @include respond(tab-land) {
    padding: 4.4rem 22rem;
    font-size: 2.2rem;
  }
  @include respond(tab-port) {
    padding: 4.2rem 19rem;
    font-size: 2rem;
  }
  @include respond(big-phone) {
    padding: 4rem 15rem;
    font-size: 1.8rem;
  }
  @include respond(phone) {
    padding: 4rem 10rem;
    font-size: 1.5rem;
  }

  @include respond(small-phone) {
    padding: 3rem;
    font-size: 1rem;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    p {
      transform: scale(1.1, 1.05);
      // transform: scaleX(1.2);
      // transform: scaleY(1.1);
    }
  }
}

.last {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-gap: 2rem;
  height: 90%;
  padding: 2rem 4rem;

  @include respond(small-phone) {
    display: inline;
    padding: 2 rem;
  }

  @include respond(big-phone) {
    grid-template-rows: repeat(4, 1fr);
  }

  &__box {
    object-fit: cover;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2rem;

    @include respond(small-phone) {
      margin: 2rem;
    }

    &:hover {
      @supports (-webkit-backdrop-filter: blur(10px)) or
        (backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -moz-backdrop-filter: blur(10px);
      }
    }

    &-1 {
      background-image: linear-gradient(
          to right,
          rgba($color-green-dark-2, 0.5),
          rgba($color-green-dark-2, 0.5)
        ),
        url("../img/rubiks-1w-ConvertImage.png");
      background-position: center;
      background-size: cover;
      grid-column: 1 / span 2;
      grid-row: 1 / span 3;
      p {
        text-align: end;
        color: #115a44;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 40rem;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: bold;
      }

      @include respond(big-phone) {
        grid-column: 1 / span 3;
        grid-row: 1 / span 2;
      }

      // &:hover {
      //   background-image: linear-gradient(
      //       to right,
      //       rgba($color-green-dark-2, 0.8),
      //       rgba($color-green-dark-2, 0.8)
      //     ),
      //     url("../img/rubiks-1w.png");
      // }
    }

    &-2 {
      background-image: linear-gradient(
          to right,
          rgba($color-yellow, 0.3),
          rgba($color-yellow, 0.3)
        ),
        url("../img/Coworking-1-ConvertImage.png");
      background-position: center;
      background-size: cover;
      grid-column: 3/-1;
      grid-row: 1 / span 3;
      p {
        text-align: end;
        color: #7d5b1d;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 54rem;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: bold;
      }

      @include respond(big-phone) {
        grid-column: 4/-1;
        grid-row: 1 / span 2;
      }

      // &:hover {
      //   background-image: linear-gradient(
      //       to right,
      //       rgba($color-yellow, 0.4),
      //       rgba($color-yellow, 0.4)
      //     ),
      //     url("../img/Coworking-1-ConvertImage.png");
      // }
    }

    &-3 {
      background-image: linear-gradient(
          to right,
          rgba($color-orange-dark-1, 0.3),
          rgba($color-orange-dark-1, 0.3)
        ),
        url("../img/basketball3.png");

      background-position: center;
      background-size: cover;
      grid-column: 1 / span 3;
      grid-row: 4/-1;
      p {
        text-align: end;
        color: #732906;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 42rem;
        font-size: 1.4rem;
        line-height: 3rem;
        font-weight: bold;
      }

      @include respond(big-phone) {
        grid-row: 3/-1;
      }

      // &:hover {
      //   background-image: linear-gradient(
      //       to right,
      //       rgba($color-orange-dark-1, 0.8),
      //       rgba($color-orange-dark-1, 0.8)
      //     ),
      //     url("/img/basketball3.png");
      // }
    }

    &-4 {
      background-image: linear-gradient(
          to right,
          rgba($color-green-dark-1, 0.6),
          rgba($color-green-dark-1, 0.6)
        ),
        url("../img/notebook-hero-workspace-minimal-e1456334971390.png");

      background-position: center;
      background-size: cover;
      grid-column: 4/-1;
      grid-row: 4/-1;
      p {
        text-align: end;
        color: #2b463e;
        white-space: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 40rem;
        line-height: 3rem;
        font-size: 1.4rem;
        font-weight: bold;
      }

      @include respond(big-phone) {
        grid-row: 3/-1;
      }

      // &:hover {
      //   background-image: linear-gradient(
      //       to right,
      //       rgba($color-green-dark-1, 0.8),
      //       rgba($color-green-dark-1, 0.8)
      //     ),
      //     url("../img/notebook-hero-workspace-minimal-e1456334971390.png");
      // }
    }

    h5 {
      margin: 1rem 0;
      font-size: 1.3rem;
    }

    div {
      align-self: flex-start;
      margin-top: 0.5rem;
    }
  }
}

.colros {
  &2 a {
    color: #8c4b48;
  }
  &1 a {
    color: #445d66;
  }
  &4 a {
    color: #647354;
  }
  &3 a {
    color: #806756;
  }
}
