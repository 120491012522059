.PhoneCourseInfoBox {
  min-height: 260rem;
  @include respond(smallest-phone) {
    min-height: 320rem;
  }
}

.CourseInfoSideBar {
  position: fixed;
  z-index: 10;
  margin-top: 2rem;
  @include respond(phone) {
    margin-left: 6rem;
    margin-top: 5rem;
  }
}
.CourseInfoLoader {
  position: fixed;
  z-index: 10;
  // margin-top: 2rem;
  // margin-left: 20rem;
  top: 50%;
  left: 50%;
  // transform: translate(-50%, -50%);
  @include respond(phone) {
    margin-left: 6rem;
    margin-top: 5rem;
  }
}
.CourseInfStickDotBox {
  position: fixed;
  z-index: 10;
  top: 22rem;
  right: 0rem;
}
.courseInfo__ScrollDot {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #fe7f2d;
  transition: all 0.3s;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
.CourseInfo__endBtn {
  margin-top: 2rem;
  margin-left: 5rem;
  display: flex;
  color: $color-orange-dark-1;
  align-items: center;
  font-size: 1.5rem;
  .fas {
    margin-top: 1rem;
    margin-right: 1.2rem;
  }
}
.CourseInfoContentBody_Box {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 2rem;
  @include respond(phone) {
    margin-top: 12rem;
    padding: 2rem 8rem 0 35rem;
  }
}
.CourseInfoContentBody {
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
}
