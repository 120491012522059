.CourseContent {
  padding: 0 2rem;
  display: grid;
  grid-template-columns: 1fr 6fr;
  grid-column-gap: 0rem;
  width: 100%;
  @include respond(tab-land) {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  @include respond(big-desktop) {
    grid-template-columns: 2fr 1.5fr;
    padding-left: 10rem;
  }
}
// .CourseContent-container{
//   width: 77%;
//   display: flex;
//   flex-direction: column;
// }