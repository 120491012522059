.CourseContent1 {
  position: fixed;
  z-index: 10;
  right: 3.6rem;
  top: 10rem;
  width: 23%;
  display: flex;
  flex-direction: column;
  @include respond(tab-land) {
    position: relative;
    width: 53%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  @include respond(big-desktop) {
    width: 30%;
    // display: flex;
    // flex-direction: column;
    // margin: 0 auto;
  }
}
