.login-form {
  width: 100%;
  height: 100vh;
  background-color: rgba($color-white, $alpha: 0.5);
  //z-index: 999999999999;
  position: relative;
  @include center;

  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($color: $color-white, $alpha: 0.5);
  }

  .popupform {
    border-radius: 2rem;
    width: 60%;
    background-color: $color-white;
    box-shadow: 0 2rem 4rem rgba($color-black, 0.2);
    overflow: hidden;
    transition: all 0.5s 0.2s;
    display: flex;
    height: 77vh;
    margin-top: 42px;
    background-color: $color-grey-light-2;

    @include respond(tab-land) {
    }

    @include respond(phone) {
      width: 90%;
    }

    flex-direction: row-reverse;

    @include respond(tab-land) {
      height: 65vh;
    }

    &__left {
      // clip-path: circle(560px at left);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;
      padding: 4rem 7rem;
      margin-left: 0;
      background-position: center;
      background-size: cover;
      background: linear-gradient(to bottom, #90be6d, #68b47d 50%, #43aa8b 75%);
      text-align: center;
      &-logo {
        width: 15rem;
        transform: translate(3rem, -4rem);
      }
      &-h2 {
        color: #fff;
        font-size: 3rem;
      }
      &-p {
        margin: 2rem 0;
        font-size: 1.5rem;
        white-space: nowrap;
        color: #fff;
      }
      &-content {
        & > *:not(:last-child) {
          margin-bottom: 3rem;
        }

        display: flex;
        flex-direction: column;
      }

      &-btn {
        background-color: transparent;
        border-radius: 5rem;
        color: #fff;
        border: 1px solid #fff;
        padding: 5px 2rem;
        &:disabled {
          justify-content: space-between;
        }
        a {
          color: $color-green-dark-2;
        }

        &:hover {
          outline: none;
        }
        &:focus {
          outline: none;
          cursor: pointer;
        }

        &--login {
         
          color: $color-white;
          border: 2px solid $color-green-dark-2;

          &:focus-within #login-form > form {
            display: none;
          }
        }

        &--signup {
          border: 2px solid $color-green-dark-2;
         
          color: $color-green-dark-2;
        }

        img {
          width: 3rem;
          margin-left: 0.5rem;
        }
      }

      .login-by {
        position: relative;
        color: $color-grey-dark;

        &::before {
          content: "";
          width: 7rem;
          height: 1px;
          background-color: $color-grey-dark;
          position: absolute;
          top: 8px;
          left: 0;

          @include respond(tab-land) {
            width: 7rem;
          }

          @include respond(big-phone) {
            width: 6rem;
          }
        }

        &::after {
          content: "";
          width: 7rem;
          height: 1px;
          background-color: $color-grey-dark;
          position: absolute;
          top: 8px;
          right: 0;

          @include respond(tab-land) {
            width: 7rem;
          }

          @include respond(big-phone) {
            width: 6rem;
          }
        }
      }

      .social {
        display: flex;
        justify-content: center;
        margin-top: -1.8rem;

        img {
          width: 5rem;
          padding: 0.4rem 0.8rem;
        }
      }
    }

    &__right {
      width: 50%;
      margin: 4rem;
      text-align: end;

      &-signup {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 100%;
        opacity: 1;
        visibility: visible;

        transition: all 0.1s;

        @include respond(big-phone) {
          left: 21%;
        }

        @include respond(phone) {
          left: 15%;
        }

        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: -6rem;

          input {
            margin: 1rem 0;
            width: 80%;
            line-height: 3rem;
            background-color: $color-grey-light-2;
          }

          button {
            border: none;
            outline: none;
            padding: 1rem;
            width: 30%;
          }

          a {
            align-self: flex-end;
            width: 17rem;
            padding: 0.5rem 0;

            @include respond(phone) {
              width: 15rem;
              padding: 0.3rem;
            }
          }
        }
      }

      &-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        transition: all 0.1s;

        form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          input {
            margin: 0.5rem 0;

            line-height: 3rem;
          }

          button {
            border: none;
            outline: none;
            padding: 1rem;
            width: 30%;
          }

          a {
            width: 50%;
            color: #43aa8b;
          }
          p {
            align-self: flex-end;

            color: $color-grey-dark;
            &:hover{
              color: #43aa8b;

            }
            a {
              text-decoration: underline;
              color: currentColor;
            }
          }
          input[type="checkbox"] {
            width: 2rem;
          }
          .check {
            color: $color-grey-dark;
            display: flex;
            width: 100%;
            flex-direction: row-reverse;
            flex-wrap: nowrap;
            align-items: center;
          }
        }
      }
    }
  }
}

.popup__close {
  position: absolute;
  width: 2rem;
  font-size: 5rem;
  color: $color-green-dark-2;
  top: -18px;
  right: 43px;
  padding: 2rem;
  height: 2rem;
}
#log-in .popupform__left-btn--login {
  background-color: $color-green-dark-2;
  align-items: center;
  transition: all 0.5s;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
  }
  img {
    width: 3rem;
  }
}
#signup .popupform__left-btn--signup {
  justify-content: space-between;
  background-color: $color-green-dark-2;
  align-items: center;
  transition: all 0.5s;

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-white;
  }
  img {
    width: 2rem;
    svg {
      fill: &color-white;
    }
  }
}
.btn-title {
  display: flex;
  align-items: center;
}
@keyframes ToLeft {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(-440px);
  }
}
.toleft {
  animation: ToLeft 0.3s linear;
  transform: translateX(0px);
}
@keyframes ToRight {
  from {
    transform: translateX(0px);
  }

  to {
    transform: translateX(330px);
  }
}
.toRight {
  animation: ToRight 0.3s linear;
  transform: translateX(0px);
}
.field {
  position: relative;
  min-height: 40px;
  width: 80%;
  margin-top: 10px;
}
.popupform{
  .field-label{
    span{
      background-color: #f6f8f9;
    }
  }
}
.field-label {
  top: 0;
  margin: -52px 25px;
  display: block;
  color: rgb(27, 27, 27);
  line-height: 62px;
  height: 24px;
  font-size: 1.4rem;
  transition: font-size 0.15s, line-height 0.15s;
  // @include respond(smallest-phone) {
  //   top: 5rem;
  // }

  span {
    background-color: #f6f8f9;
    padding: 0 5px;
    transition: all 0.2s;
    position: relative;
    z-index: 9999;
    @include respond(smallest-phone) {
      top: 0.65rem;
    }
  }
}
// .field-label1 {
//   position: relative;
//   right: 0;
//   top: -2rem;
//   display: block;
//   color: rgb(27, 27, 27);
//   line-height: 62px;
//   height: 24px;
//   font-size: 1.4rem;
//   transition: font-size 0.15s, line-height 0.15s;
//   span {
//     background-color: #f6f8f9;
//     padding: 0 5px;
//     transition: all 0.2s;
//     position: relative;
//     z-index: 9999;
//   }
// }
.field-input {
  position: relative;
  display: block;
  width: 100%;
  padding: 8px 3rem;
  line-height: 16px;
  font-size: 16px;
  background: transparent;
  -webkit-appearance: none;
  outline: none;
}
.field-input {
  height: 32px;
  // @include respond(smallest-phone) {
  //   height: 5rem;
  // }
}

 
.field-input:invalid:focus ~ .field-label,
.field-textarea:invalid:focus ~ .field-label,
.field-input:valid ~ .field-label,
.field-textarea:valid ~ .field-label ,
.field-input:placeholder-shown ~ .PhoneFormfield-label{
  font-size: 12px;
  line-height: 25px;
  @include respond(smallest-phone) {
    font-size: 10px;
    line-height: 30px;
  }
}
.field-input:-webkit-autofill,
.field-input:-webkit-autofill:hover, 
.field-input:-webkit-autofill:focus
 {
  
  transition: background-color 5000s ease-in-out 0s;
}
.field-input:focus ~ .field-label::after,
.field-textarea:focus ~ .field-label::after {
  transform: scaleX(1);
  @include respond(smallest-phone) {
    transform: scaleX(0.1);
  }
}

.popupform__right-login-img {
  margin-left: 3px;
  width: 15px;

  position: absolute;
  right: 1rem;
  top: 12px;
  color: #323232;
  display: flex;
  align-items: center;
  opacity: 1;
  z-index: 99;
  font-size: 1.2rem;
}
.popupform__right--logo {
  width: 163px;
}
.popupform__right-heading {
  align-self: flex-end;
  margin-bottom: 10px;
}
.login-question-box {
 
    width: 77%;
    margin: 1rem;
  a {
    color: #323232;
    padding-right: 35px;
  }
  .check {
    width: 36% !important;
    p {
      color: #323232 !important;
    }
  }
}

.formInput_eyeBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .fas {
    color: $color-green-dark-2;
    font-size: 1.5rem;
    position: absolute;
    left: 1rem;
    top: 1.4rem;
  }
}

.fadeOut {
  opacity: 0;
  transition: all 0.01s ease-out;
}
.form__display{
  .field-label span{
    background-color: #fdfdfd;
  }
}
.responsivelogin{
  background: linear-gradient(to bottom, #90be6d, #68b47d 50%, #43aa8b 75%);

   .popupform__right {
    width: 64%;
    margin: 4rem;
    text-align: end;
    background: #ffffff;
    padding: 20px;
    border-radius: 17px;
}

}