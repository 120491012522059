.MyCourse__container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  padding: 10px 40px;
  grid-gap: 50px;
  margin-top: 3rem;
  @include respond(tab-land) {
    grid-template-columns: 3fr 1.25fr;
    grid-gap: 10px;
  }
  @include respond(tab-port) {
    grid-template-columns: 3fr 1.25fr;

    grid-gap: 20px;
  }
  @include respond(biger-phone) {
    grid-template-columns: 1fr;

    grid-gap: 10px;
  }
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
.MyCourse__background {
  background-image: url("../../img/sabadcala/Path\ 284.svg");
  background-size: cover;
  background-position: center;
  margin-top: 10rem;
  min-height: 84vh;
  @include respond(big-phone) {
    margin-top: 16rem;
  }
}
.mycourse-defaultbox {
}

.MycourseMainPanel__header {
  display: flex;
  align-items: center;

  box-shadow: 0 1px 5px #eeeeee;
  .MycourseMainPanel__button {
    width: 100%;
    text-align: center;
    font-size: 1.6rem;
    padding: 18px;
    cursor: pointer;
    &:not(:first-child) {
      border-left: 1px solid #eeeeee;
    }
  }
}
.NowCourseMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  .empty__img {
    opacity: 0.4;
    width: 300px;
  }
}
.MyCourseNowCard {
  display: flex;
  flex-direction: row-reverse;
  margin: 30px 0 !important;
  transition: 0.2s ease-in-out;

  @include respond(tab-port) {
    flex-direction: column;
  }
  @include respond(big-phone) {
    flex-direction: row-reverse;
  }
  @include respond(small-phone) {
    flex-direction:column;
  }
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 2px 5px #bdbdbd;
  }
}
.MyCourseNowCard__main {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 20px;
  width: 380px;
  @include respond(tab-land) {
    width: 330px;
  }
  @include respond(tab-port) {
    width: 280px;
  }
  @include respond(big-phone) {
    width: 100%;
  }
}
.MyCourseNowCard__header {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  text-align: right;
    white-space: nowrap;
  width: 100%;
  h3 {
    font-size: 1.7rem;
  }
}
.MyCourseNowCard__row {
  margin: 4px 0;
}
.MyCourseNowCard__row,
.MyCourseNowCard__text {
  font-size: 1.5rem;
}
img.MyCourseNowCard__point--img {
  margin: 0 5px;
  width: 17px;
}
.MyCourseNowCard--img {
  width: 215px;
  @include respond(tab-port) {
    width: 100%;
    height: 200px;
  }
  @include respond(phone) {
    width: 70%;
    height: 200px;
  }
  @include respond(small-phone) {
    width: 100%;
    height: 200px;
  }
}
.mycourse-defaulbody {
  display: flex;
  flex-direction: column;
}
.MyCourseNowCard__row-box {
  padding: 0 5px;
  border-left: 2px solid #929292;
  margin: 0 5px;
}
.MyCourseNowCard__point {
  font-size: 1.5rem;
  margin: 8px 0;
}
.MyCourseNowCard__text--orange {
  color: #fe8c42;
  margin-right: 2px;
}
.MyCourseNowCard__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .BtnPrimary {
    border-radius: 50px;
  }
}
.active__btn {
  position: relative;
  color: #ffffff;
  p {
    z-index: 9999999;
    position: relative;
  }
  &::before {
    content: "";
    background: linear-gradient(to bottom, #90be6d, #68b47d 50%, #43aa8b 75%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    animation: toRight 0.7s ease-in-out;
    animation-iteration-count: 1;
  }
}
.active__btn1 {
  position: relative;
  color: #ffffff;
  p {
    z-index: 9999999;
    position: relative;
  }
  &::before {
    content: "";
    background: linear-gradient(to bottom, #90be6d, #68b47d 50%, #43aa8b 75%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    animation: toLeft 0.7s ease-in-out;
    animation-iteration-count: 1;
  }
}

.active__tabcourse1 {
  position: relative;

  animation: toLeft 0.7s ease-in-out;
  animation-iteration-count: 1;
}
.active__tabcourse {
  position: relative;

  animation: toRight 0.7s ease-in-out;
  animation-iteration-count: 1;
}
@keyframes toRight {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}
@keyframes toLeft {
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
}
.name__sec{
  font-size: 15px;
  justify-content: center;
  flex-direction: row-reverse;
}
.QuizeModal__row {
  margin: 10px 21px;
  direction: rtl;
  color: #000;
  font-size: 14px;
  a{
    color: #000;

  }
}