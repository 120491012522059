.SarFaslBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  width: 100%;
  // transition: all ease-in-out 1s;
}
.SarFaslBoxContent {
  display: flex;
  flex-direction: column;
  padding-right: 7rem;
  padding-left: 7rem;
  transition: all 0.6s ease;
  // transition: all ease-in-out 1s;
  @include respond(tab-land) {
    padding-right: 0;
    width: 95%;
    margin: 0 auto;
    padding-left: 0;
  }
}
.Sarfasl__Line {
  width: 100%;
  height: 2px;
  background-color: $color-grey-light-3;
}
.SarFasl__label {
  font-size: 1.8rem;
  font-weight: 100;
}
.SarFasl__select {
  border: none;
}
.SarFasl__selectBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .fas {
    font-size: 2rem;
    cursor: pointer;
  }
  & > * {
    margin-left: 2rem;
  }
}
.SarFasl__lessonBox {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 2rem;
  }
}
.SarFasl__lesson {
  font-size: 1.4rem;
  color: $color-black-dark;
  margin-bottom: 0.5rem;
}
.SarFasl__houer {
  font-size: 1.4rem;
  color: $color-black-dark;
}

.InfoSarfaslListIcoBox {
  margin-left: 2rem;
  .far {
    font-size: 1.5rem;
  }
}
.InfoSarfaslListBox {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1.25rem;
  width: 100%;
}
.InfoSarfaslList__selectBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  .fas {
    font-size: 2rem;
    cursor: pointer;
  }
}

.InfoSarfaslListBox__withIco {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.InfoSarfaslList__label {
  font-size: 1.5rem;
  font-weight: 100;
  direction: rtl;
}
.InfoSarfaslList__lessonBox {
  display: flex;
  align-items: center;
  & > * {
    margin-left: 2rem;
  }
}
.InfoSarfaslList__lesson {
  font-size: 1.4rem;
  color: $color-black-dark;
  direction: rtl;
}
.InfoSarfaslList__houer {
  font-size: 1.4rem;
  color: $color-black-dark;
}

.Sarfasl__extraProperties {
  width: 100%;
  height: auto;
  -webkit-transition: height 0.6s linear;
  -moz-transition: height 0.6s linear;
  -ms-transition: height 0.6s linear;
  -o-transition: height 0.6s linear;
  transition: height 0.6s linear;
}
.Sarfasl__Hide-extraProperties {
  height: 0;
  -webkit-transition: height 0.6s linear;
  -moz-transition: height 0.6s linear;
  -ms-transition: height 0.6s linear;
  -o-transition: height 0.6s linear;
  transition: height 0.6s linear;
}
.Sarfasl__extraDeatailes {
  margin-top: 2rem;
  margin-bottom: 2rem;
  transition: all 0.3s ease-in-out;
}
button.accordioninfo {
  border-bottom: 2px solid #efeaea;
}
