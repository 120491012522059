.PhoneForm__head {
  &__propertyBox {
    margin-top: 1.9rem;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__propertyTitle {
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
    display: flex;
    line-height: 0;
    justify-content: center;
    @include respond(smallest-phone) {
      font-size: 1.5rem;
    }
  }
  &__firstLine {
    width: 30%;
    height: 5rem;
    @include respond(smallest-phone) {
      width: 37%;
      height: 11rem;
    }
  }
  &__SecLine {
    width: 30%;
    height: 5rem;
    @include respond(smallest-phone) {
      width: 37%;
      height: 11rem;
    }
  }
}

.PhoneForm {
  margin: 1.9rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__display {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.footer__PhoneForm {
  //font-size: 12px;
  display: flex;
  //   flex-direction: column;
  text-align: right;
  justify-content: center;
  flex-wrap: wrap;
  & > *:not(:last-child) span::after {
    content: "*";
    color: $color-orange-dark-1;
    font-size: 2rem;
  }
  //   & > *:nth-child(4) span::after {
  //     content: "";
  //     color: $color-orange-dark-1;
  //     font-size: 2rem;
  //   }
  .PhoneFormfooterInput {
    width: 83vw;
    margin: 0 0 5.4rem 0;
    @include respond(smallest-phone) {
      margin: 0 0 8.4rem 0;
    }
  }

  .PhoneFormfield-input:invalid:focus ~ .PhoneFormfield-label,
  .PhoneFormfield-textarea:invalid:focus ~ .PhoneFormfield-label,
  .field-input:valid ~ .PhoneFormfield-label,
  .PhoneFormfield-textarea:valid ~ .PhoneFormfield-label
   {
    font-size: 12px;
    line-height: 34px;
    // @include respond(smallest-phone) {
    //   line-height: 20px;
    // }
  }
  .PhoneFormfield-input:invalid:focus,
  .PhoneFormfield-input:valid {
    border: 1px solid $color-orange-dark-1;
  }
  .PhoneFormfield-input:valid {
    border: 1px solid $color-grey-dark;
  }
  &__input {
    flex: 0 0 100%;
    text-align: right;
    border: none;
    border-bottom: 1.2px solid $color-grey-dark;
    padding-bottom: 0.75rem;
    font-size: 1.5rem;
    color: #a6adb4;

    &:focus {
      outline: none;
    }
  }
}
.text-areaLabel {
  position: relative !important;
  top: -7rem !important;
  @include respond(smallest-phone) {
    top: -5rem !important;
  }
}
.text-area-PhoneForm-input {
  width: 83% !important;
  position: relative;

  .text-area-PhoneForm {
    min-height: 12rem;
    max-height: 12rem;
    min-width: 100%;
    max-width: 100%;
  }
}
.FeedbackPhoneFormBtn {
  font-size: 2rem;
  padding: 1rem 3rem;
  border-radius: 3rem;
}
.PhoneForm__btn {
  margin: 7rem auto 0 auto;
}
.field-label {
  top: 0;
  margin: -52px 25px;
  display: block;
  color: rgb(27, 27, 27);
  line-height: 62px;
  height: 24px;
  font-size: 1.4rem;
  transition: font-size 0.15s, line-height 0.15s;
  @include respond(smallest-phone) {
    top: 1rem;
  }
  span {
    background-color: white;
    padding: 0 5px;
    transition: all 0.2s;
    position: relative;
    z-index: 9999;
    @include respond(smallest-phone) {
      padding: 0 3px;
    }
  }
}
