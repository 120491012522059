@import "./variable";
@import "./mixin";
.signup-btn {
  padding: 5px 1rem;
  width: 100px;
}
.navigation__Back {
  position: fixed;
  z-index: 300000;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
  top: 0;
  width: 100%;
  left: 0;
  background-color: rgba($color-white, $alpha: 0.5);
}
.navigation {
  display: grid;
  grid-template-columns: repeat(2, minmax(max-content, 70%));
  padding: 2rem 10rem;

  @include respond(tab-land) {
    padding: 1rem;
  }

  @include respond(big-phone) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  @supports (-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -moz-backdrop-filter: blur(10px);
  }

  .nav {
    display: flex;
    align-items: center;
    font-size: 1.3rem;

    @include respond(big-phone) {
      justify-content: center;
      width: 100%;
      padding: 1rem;
    }

    @include respond(small-phone) {
      font-size: 1.2rem;
    }

    @include respond(smallest-phone) {
      font-size: 2rem;
      padding: 1rem 0;
    }

    &__btn {
      border: 2px solid $color-green-dark-2;
      background-color: $color-white;
      margin: 0 0.8rem;
      border-radius: 1.8rem;
      width: 15%;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s;
      padding: 0.5rem 0.5rem;

      &:focus {
        outline: none;
      }
    }

    .log-in {
      background-color: $color-green-dark-2;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-green-dark-2;
      }

      @include respond(smallest-phone) {
        padding: 0.8rem 0.5rem;
      }
    }

    .sign-up {
      color: $color-green-dark-2;

      &:hover {
        background-color: $color-green-dark-2;
        color: $color-white;
      }
    }

    &__teacher {
      margin: 0 0.8rem;
      color: $color-green-dark-2;
      border-left: 2px solid $color-grey-dark;
      border-right: 2px solid $color-grey-dark;
      padding: 0 1rem;

      &:link,
      &:hover {
        color: $color-grey-dark;
      }
    }

    &__list {
      display: flex;
      flex-direction: row-reverse;
      color: $color-black;
      margin: 0 0.8rem;
      justify-content: space-between;

      &--item {
        margin: 0 0.8rem;
      }

      &--link {
        color: $color-black;
      }

      &--link:hover {
        color: $color-green-dark-2;
      }
    }
  }
  .navIcon {
    font-size: 0;
  }
  &__logo {
    justify-self: end;
  }
  .search {
    color: $color-grey-dark;
    border-radius: 50%;
    border: 2px solid $color-grey-dark;
    padding: 0.6rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    i {
      font-size: 1.5rem;
    }
  }
}
.is-active {
  color: $color-green-dark-2;
}

.Profile__box {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  &-1 {
    &:hover .Profile__submenu {
      display: block;
      opacity: 1;
    }
  }

  .cardIcon {
    width: 20px;
    &:hover {
      opacity: 0.5;
    }
  }
  .ringIcon {
    width: 25px;
    margin-top: 3px;
  }
  &--icon {
    margin: 0 8px;
    cursor: pointer;
  }
  &--img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .Profile__submenu {
    position: absolute;
    background-color: #ffffff;
    width: 120px;
    top: 40px;
    display: none;
    opacity: 0;
    z-index: 400000;
    border: 1px solid #dedede;
    box-shadow: 1px 1px 5px #dedede;
    border-radius: 5px;
    ul {
      list-style: none;
      li {
        padding: 10px;
        cursor: pointer;
        text-align: right;
        a {
          text-decoration: none;
          color: #000;
          &:hover {
            color: $color-orange-dark-1;
          }
        }
        &:hover {
          color: $color-orange-dark-1;
        }
        &:not(:last-child) {
          border-bottom: 1px solid #dedede;
        }
      }
    }
  }
}
.Profile__box--img {
  img {
    width: 40px;
    height: 40px;
  }
}
.cardIcon__Notification {
  font-size: 1rem;
  color: white;
  position: absolute;
  bottom: 4px;
  background-color: red;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  align-items: baseline !important;
}
