.CourseInfoTableContent__Row {
  padding: 0 1.5rem 0 4rem;

  display: flex;
  align-items: center;
  @include respond(phone) {
    padding: 0 1rem 0 1rem;
  }
}
.CourseInfoTableContent__firstRow {
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 1.2rem;
  p {
    font-size: 1.3rem;
    font-weight: 400;
    color: $color-black-dark;
    @include respond(phone) {
      font-size: 1.1rem;
    }
  }
  .fa-bookmark {
    color: $color-orange-dark-1;
    font-size: 1.5rem;
    font-weight: 100;
    @include respond(phone) {
      font-size: 1.3rem;
    }
  }
}

.CourseInfoTableContent__SecondRow {
  flex-direction: row-reverse;
  margin-top: 0.5rem;
  &-div {
    width: 3.65rem;
    height: 2.8rem;
    @include respond(phone) {
      width: 1.65rem;
      height: 1.8rem;
    }
  }
  img {
    width: 3.65rem;
    height: 2.8rem;
    @include respond(phone) {
      width: 1.65rem;
      height: 1.8rem;
    }
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    margin-right: 0.5rem;
    p {
      font-size: 1rem;
      font-weight: 400;
      @include respond(phone) {
        font-size: 0.8rem;
        font-weight: 300;
      }
    }
    & > :first-child {
      color: $color-black-dark;
    }
    & > :last-child {
      color: $color-grey-light-4;
    }
  }
}
.CourseInfoTableContent__ThirdRow {
  flex-direction: row-reverse;
  margin-top: 0.5rem;
  p {
    font-size: 1.1rem;
    font-weight: 400;
    @include respond(phone) {
      font-size: 0.8rem;
      font-weight: 300;
    }
  }
  & > :first-child {
    color: $color-black-dark;
  }
  & > :last-child {
    color: $color-grey-light-4;
    margin-right: 0.5rem;
  }
}

.CourseInfoTableContent__ForthRow {
  flex-direction: row-reverse;
  margin-top: 0.6rem;
  p {
    font-size: 1rem;
    font-weight: 300;
    color: $color-grey-light-4;
    margin-right: 0.88rem;
    @include respond(phone) {
      font-size: 0.8rem;
      font-weight: 100;
      margin-right: 0.5rem;
    }
  }
  div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
  }
  & > :last-child {
    img {
      width: 1.4rem;
      height: 1.4rem;
      @include respond(phone) {
        width: 1rem;
        height: 1rem;
      }
    }
  }
  .fas {
    color: $color-yellow;
    font-size: 1.2rem;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  .CourseInfoTableContent__Line {
    width: 1.25px;
    height: 1.8rem;
    background-color: $color-grey-dark-1;
    margin: 0 1.4rem;
    @include respond(phone) {
      width: 1px;
      height: 1.3rem;
    }
  }
}
.CourseInfoTableContent__FifthRow {
  flex-direction: row-reverse;
  margin-top: 0.6rem;
  .far {
    color: $color-green-dark-1;
    font-size: 1.1rem;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  p {
    font-size: 1rem;
    font-weight: 300;
    color: $color-grey-light-4;
    margin-right: 0.88rem;
    @include respond(phone) {
      font-size: 1rem;
      margin-right: 0.5rem;
    }
  }
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: $color-orange-light-1 !important;
}
.MuiInput-underline:before {
  border-bottom: 1px solid $color-orange-dark !important;
  &:focus {
    background-color: transparent !important;
  }
}
.MuiInput-underline:after {
  border-bottom: 2px solid $color-orange-light !important;
  background-color: transparent !important;
}
.MuiSelect-nativeInput {
  background-color: transparent !important;
  &:focus {
    background-color: transparent !important;
  }
}
.MuiSelect-icon {
  top: 0.5rem !important;
}
.PriceSection__firstRow div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  border-radius: 22px;
  padding: 5px 5px;
  border-color: #f3722c;
  // margin-left: 1rem;
  svg.MuiSelect-icon.MuiSelect-iconOutlined {
    width: 10px !important;
    fill: #f3722c;
    padding: 5px 0;
  }
}
.MuiOutlinedInput-input {
  padding: 5px 20px !important;
  &:focus {
    background-color: #fff !important;
  }
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fcc845 !important;
  border-width: 2px;
}
.MuiInputBase-root.MuiOutlinedInput-root.Mui-focused.Mui-focused.MuiInputBase-formControl {
  border-color: red;
}
