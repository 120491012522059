.SarfaslTable {
  border: 1px solid $color-grey-light-3;
  width: 100%;
  // margin-top: 1.6rem;
  // padding-bottom: 1rem;
  // padding: 0rem 1rem 1.5rem 1rem;
  overflow: hidden;
  font-size: 1.5rem;
}
.SarfaslTable__Box {
  max-width: 100%;
  & > :first-child {
    text-align: center;
  }
  thead {
    background-color: $color-green-dark-2;
    background-size: cover;
    // padding-bottom: 0.25rem;
    // padding-top: 0.5rem;
    text-align: center;
    p {
      font-size: 1.5rem;
      color: $color-white;
      font-weight: 400;
      margin: 1rem auto;
    }
  }
  tbody {
    padding: 0rem 2rem 1.5rem 2rem;
    font-size: 1.5rem;
  }
}
.MuiAccordionSummary-root {
  flex-direction: row;
  justify-content: space-between;
  direction: rtl;
}
.MuiButtonBase-root {
  justify-content: space-between;
}
.MuiAccordionSummary-content {
  align-items: flex-end;
}
