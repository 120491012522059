.feedbacksection {
  background-color: #fdfdfd;
  padding-bottom: 4rem;
}
.form__display {
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
 
}

.footer__form {
  //font-size: 12px;
  display: flex;
  text-align: right;
  justify-content: center;
  flex-wrap: wrap;
  & > * span::after {
    content: '*';
    color: $color-orange-dark-1;
    font-size: 2rem;
  }
  & > *:nth-child(4) span::after {
    content: '';
    color: $color-orange-dark-1;
    font-size: 2rem;
  }
  .footerInput {
    width: 32%;
    margin: 5px 10px;
  }

  .field-input:invalid:focus ~ .field-label,
  .field-textarea:invalid:focus ~ .field-label,
  .field-input:valid ~ .field-label,
  .field-textarea:valid ~ .field-label {
    font-size: 12px;
    line-height: 34px;
  }
  .field-input:invalid:focus,
  .field-input:valid {
    border: 1px solid $color-orange-dark-1;
  }
  .field-input:valid {
    border: 1px solid $color-grey-dark;
  }
  &__input {
    flex: 0 0 30%;
    text-align: right;
    border: none;
    border-bottom: 1.2px solid $color-grey-dark;
    padding-bottom: 0.75rem;
    font-size: 1.5rem;
    color: #a6adb4;

    &:focus {
      outline: none;
    }

    &:nth-child(3) {
      color: $color-green-dark-2;
      border-bottom: 1.2px solid $color-grey-dark;
    }

    &-2 {
      display: inline-block;
      margin-right: 5rem;
      margin-bottom: 4rem;
    }
    &-1 {
      display: inline-block;
      margin-right: 2.5rem;
      margin-left: 5rem;
      margin-bottom: 4rem;
    }
    &-3 {
      flex: 0 0 69%;
      text-align: end;
      margin-right: 25px;
    }
  }
}

.form__btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
// .btn-pink {
//   &,
//   &:link,
//   &:checked {
//     text-transform: uppercase;
//     text-decoration: none;
//     padding: 0.5rem 1.3rem;
//     display: inline-block;
//     border-radius: 3rem;
//     text-align: center;
//     transition: background-color 0.2s, border 0.2s 0.2s;
//     background-color: $color-pink-dark;
//     color: $color-white;
//     border: 1px solid $color-pink-dark;
//     &:not(.disable):hover {
//       background-color: $color-white;
//       color: $color-pink-dark;
//       border: 1px solid $color-pink-dark;
//       cursor: pointer;
//     }
//     &:focus {
//       outline: none;
//     }
//   }
// }
.MuiInputLabel-outlined.MuiInputLabel-Shrink.Mui-focused {
  right: 0px !important;
}
.MuiInputLabel-outlined {
  z-index: 1;
  pointer-events: none !important;
  /* position: absolute; */
  right: 0 !important;
  text-align: right !important;
}
.PrivateNotchedOutline-legendLabelled-3 {
  text-align: right !important;
}
.text-area-form-input {
  width: 65.5% !important;

  .text-area-form {
    min-height: 4rem;
    max-height: 7rem;
    min-width: 100%;
    max-width: 100%;
    padding-right: 15rem;
    resize: vertical;
  }
}
.FeedbackFormBtn {
  font-size: 2rem;
  padding: 1rem 3rem;
  border-radius: 3rem;
}
.feedbackForm__select {
  border: 1.2px solid $color-grey-dark;
  border-radius: 0.5rem;
  width: 100%;
  background-color: transparent;
  height: 80%;
  direction: rtl;
  padding-left: 1.2rem;
  padding-right: 2.2rem;
  color: $color-black-dark-1;
  font-size: 1.4rem;

  &:focus {
    outline: none;
    border: 1px solid $color-green-dark-2;
  }
}
.textarea--footer{
  resize: none;
}