// .viedeo__box {
//   width: 50.6rem;
//   height: 20rem;
//   border-radius: 3rem;
//   background-color: $color-pink-light;
//   margin: 3rem auto;
//   position: relative;
// }

.video-react.video-react-fluid {
  width: auto;
  height: 2rem;
  &:focus {
    outline: none;
  }
}
.video-react .video-react-loading-spinner {
  // display: flex;
}
.video-react .video-react-video {
  width: 100%;
  // height: 2rem;

  // overflow: hidden;
  // height: 10rem;
  &:focus {
    outline: none;
  }
}
.PhoneCorseIntro__video {
  border-radius: 2rem;
  margin-left: auto;
  margin-top: 0rem;
  height: 4rem;
  background-color: $color-grey-dark-1;
}
.PhoneCorseIntro__video-Title {
  font-size: 0.9rem;
  font-weight: 400;
  color: $color-white;
  position: absolute;
  z-index: 5;
  top: 0.5rem;
  right: 10rem;

  &::before {
    content: '';
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $color-white;
    top: 0.5rem;
    right: -1rem;
  }
}

.PhoneCorseIntroPlayerBox {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-left: 5rem;
  width: 90%;
  margin: 0 auto;
  //   margin-left: 10rem;
}
