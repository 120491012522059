.how {
  padding: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0;
  
  @include respond(tab-land) {
    padding: 3rem 1rem;
  }
  & > :nth-child(odd) {
    flex-direction: row-reverse;
    align-self: flex-end;
  }

  & > :nth-child(even) {
    flex-direction: row;
    align-self: flex-start;
  }

  .how__box {
    @include center;
    @include respond(tab-land) {
      justify-content: flex-start;
    }

    &-pic {
      width: 30rem;
      height: 30rem;
      padding: 0;
      border-radius: 50%;
      @include center;
      @include respond(tab-land) {
        width: 25rem;
        height: 25rem;
      }
      @include respond(phone) {
        width: 15rem;
        height: 15rem;
      }
      background: radial-gradient($color-green-dark-1 50%, $color-green-dark-2);

      img {
        object-fit: cover;
        width: 28rem;
        border-radius: 6%;
        @include respond(tab-land) {
          width: 15rem;
        }
        @include respond(phone) {
          width: 14rem;
        }
        @include respond(small-phone) {
          width: 12rem;
        }
      }
    }

    &-content {
      flex-basis: 30rem;
      padding: 1rem;
      margin: 1.8rem;

      h4 {
        margin-bottom: 0.8rem;
      }

      p {
        font-size: 1.3rem;
        color: #233d4d;
      }

      &--1 {
        text-align: end;
      }

      &--2 {
        text-align: start;
      }

      &--3 {
        text-align: end;
      }

      &--4 {
        text-align: start;
      }
    }
  }
}
.howSec {
 
  background-image: url("../img/how-background.svg");
  background-size: cover;
  padding-top: 18rem;
  margin-top: -1px;
  margin-bottom: 120px;
  @include respond(phone) {
    background-position: center;
    margin-top: -4rem;
  }
  box-sizing: inherit;
  margin-top: -12rem;
}
.How__SectionEndButton{
  position: relative;
  z-index: 10;
  transform: translateY(62px);
}
.howSec-container{
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2rem;
}
img.How__background {
  position: absolute;
  left: -94px;
  width: 100vw;
  bottom: -142px;
  @include respond(phone) {
   display: none;
  }
}