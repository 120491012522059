.Usersetting {
  display: grid;
  grid-template-columns: 5fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  justify-content: center;
  margin-top: 10rem;
  height: 85vh;
  &__List {
    height: 100%;
    align-self: flex-start;
    justify-self: center;
    border-left: 1px solid $color-grey-light-4;
    // padding-left: 2rem;
  }
  &__content {
    align-self: flex-start;
  }
  &__Item {
    color: $color-grey-light-4;
    list-style: none;
    font-size: 1.8rem;
    font-weight: 500;
    margin-top: 2rem;
    cursor: pointer;
    padding-left: 2rem;
    &-active {
      color: $color-orange-dark-1;

      //   border-left: 2px solid $color-orange-dark-1;
    }
  }
}
