.CourseInfoFormBtn {
  padding: 1rem 2.6rem;
  font-size: 1.5rem;
  margin-top: 4rem;
  margin-bottom: 4.57rem;
}
.Toastify__toast {
  z-index: 99999999999999999999 !important;
  direction: rtl;
}
.corurseinfo__form--box{
  .corurseinfo__form--textarea{
    resize: none;
    font-family: 'Vazir', sans-serif !important;
    text-align: right;
    padding: 10px 10px;
    direction: rtl;
    outline: none;
    border-radius: 10px;
    border: 1px solid #d2cccc;
    width: 100%;
    &:focus{
     outline: none;
     border: 1px solid #adaaaa;
    }
  }
}
.corurseinfo__form--box {
  width: 100%;
  margin: 0 49px;
}