.PhoneAccordion__section {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.PhoneAccordion {
  background-color: transparent;
  color: $color-black-dark;
  cursor: pointer;
  padding: 1.9rem 3.2rem 1.5rem 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;

  outline: none;
  font-family: 'Vazir', sans-serif;
  transition: background-color 0.6s ease;
}

.PhoneAccordion:hover,
.phoneactive {
  background-color: transparent;
}

.PhoneAccordion__title {
  font-family: 'Vazir', sans-serif;
  font-weight: 100;
  font-size: 2.1rem;
}

.PhoneAccordion__icon {
  margin-right: auto;
  transition: transform 0.6s ease;
}

.Phonerotate {
  transform: rotate(90deg);
}

.PhoneAccordion__content {
  background-color: transparent;
  overflow: hidden;
  transition: max-height 0.6s ease;
}
.PhoneAccordion__Commentcontent {
  border: 0.05rem solid $color-grey-dark1;
  padding: 1rem 1.5rem 2rem 1.5rem;
  // margin-top: 4rem;
  background-color: $color-white;
  margin: 1rem;
}

.PhoneAccordion__text {
  font-family: 'Vazir', sans-serif;
  font-weight: 100;
  font-size: 1rem;
  padding: 1rem 3rem;
  direction: rtl;
}

.PhoneAccordion__contentBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin: 0.5rem 0 0 0;
}
