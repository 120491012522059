.Pishniazlist {
  // padding: 0 16.6rem 0px 15rem;
  margin-top: 3rem;
  padding-right: 7rem;
  @include respond(phone) {
    padding-right: 12.5rem;
    width: 90%;
  }
}

.corseInfo__head__propertyTitle {
  font-size: 2rem;
  font-weight: normal;
  //text-align: center;
  //  position: relative;
  display: flex;
  line-height: 0;
  @include respond(tab-land) {
    font-size: 1.4rem;
 
   }
   @include respond(tab-port) {
    font-size: 1.2rem;
 
   }
  //justify-content:flex-end;
}
.corseInfo__head__propertyBox1 {
  margin-top: 7rem;
}
.Pishniaz__Item {
  //margin-right: 2rem;
}
.PishNiazList__headFirstLine {
  width: 38%;
  height: 5rem;
  @include respond(phone) {
    width: 38%;
    height: 5rem;
  }
}
.PishNiazList__headSecLine {
  width: 38%;
  height: 5rem;
  @include respond(phone) {
    width: 38%;
    height: 5rem;
  }
}
