.Card__CourseList {
  position: relative;
  min-height: 70vh;
}
.CardCourseList__empytList {
  color: $color-green1;
  font-size: 2.5rem;
  font-weight: 500;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card__List {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
  padding: 1rem 4rem;
  background-color: $color-green-dark-2;
  grid-column-gap: 1rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  align-items: center;
  & > *:nth-child(2),
  & > *:nth-child(3),
  & > *:nth-child(4) {
    align-self: center;
    justify-self: center;
  }
  &--item {
    text-align: right;
    font-size: 1.4rem;
    color: #ffffff;
    &:last-child {
      padding-right: 2rem;
    }
  }
}
.cost-end {
  position: absolute;
  bottom: 10px;
  left: 8px;
}

.Card-madrak,
.Card-cost,
.Card-title {
  font-size: 1.6rem;
  width: auto;
}
.Card-cost {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  justify-content: center;
}
