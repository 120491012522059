.MediaShare__Button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 0.4rem 5.3rem 0.9rem 5.59rem;
  white-space: nowrap;
  @include respond(phone) {
    padding: 0.2rem 4.3rem 0.7rem 4.59rem;
  }
  p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0.5rem;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
  .fas {
    margin-right: 0.51rem;
    color: $color-white;
    font-size: 1.1rem;
    @include respond(phone) {
      font-size: 1rem;
      margin-right: 0.3rem;
    }
  }
}

.priceHeader__line {
  border-bottom: 1px solid $color-grey-light-3;
  padding-bottom: 1rem;
}
.shareIco__Box {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  & > :not(:first-child) {
    margin-right: 0.85rem;
    @include respond(phone) {
      margin-right: 0.5rem;
    }
  }
  .media-circle {
    border-radius: 50%;
    border: 1px solid $color-orange-dark-1;
    text-align: center;
    width: 32px;
    height: 32px;
   // overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    cursor:pointer;
    &:hover{
      filter: grayscale(1);
    }
    @include respond(phone) {
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}
.shareIco {
  height: auto;
  @include respond(phone) {
  }
  &-0 {

    @include respond(phone) {
    }
  }
  &-1 {
    @include respond(phone) {
    }
  }
  &-3 {
    @include respond(phone) {
    }
  }
  &-5 {
    @include respond(phone) {
    }
  }
  &-6 {
  }
}

