.mycourse-defaultbox {
    box-shadow: 0 0 5px #e4e2e2;
    border-radius: 15px;
    overflow:hidden;
    margin: 10px 0;
    background-color: #ffffff;
}
// 
.mycourse-defaultheader {
    padding: 15px;
    font-size: 1.6rem;
    box-shadow: 0 4px 5px #eaeaea;
    color: #ffffff;
    text-align: center;
    background: linear-gradient(to bottom, #90be6d, #68b47d 50%, #43aa8b 75%);
}
.lastactivity-card {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    padding: 15px 20px;
    @include respond(big-phone) {
      justify-content: center;
        }
}
.lastactivity-card--text {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    font-size: 1.5rem;
}
.lastactivity-card--progress {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}
.lastactivity__Circle {
    margin-right: 10px;
    margin-top: 10px;
}
.lastactivity-card--img {
    border: 1px solid #43aa8b;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-left: 10px;
    img{
        width: 60px;
    }
}
.lastactivity__btn{
    margin: 10px auto;
    padding: 8px 45px;
}
