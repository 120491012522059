.PhoneSimilarCurse {
  &__head {
    &__propertyBox {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    &__firstLine {
      width: 38%;
      height: 5rem;
      @include respond(smallest-phone) {
        width: 40%;
        height: 11rem;
      }
    }
    &__propertyTitle {
      font-size: 2rem;
      font-weight: normal;
      text-align: center;
      display: flex;
      line-height: 0;
      justify-content: center;
      @include respond(smallest-phone) {
        font-size: 1.5rem;
      }
    }
    &__SecLine {
      width: 38%;
      height: 5rem;
      @include respond(smallest-phone) {
        width: 40%;
        height: 11rem;
      }
    }
  }
  &__box {
    direction: rtl;
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0.5rem;
    grid-auto-rows: max-content;
    // margin-right: 7rem;
    // align-self: center;
    // justify-content: center;
    // justify-self: center;
    align-items: center;
    & > * {
      width: 75%;
      //   margin: 0 auto;
      align-self: center;
      justify-self: center;
    }
  }
}
