.ResetPassworld-container{
    background: linear-gradient(to bottom, #90be6d, #68b47d 30%, #43aa8b 45%);
    backdrop-filter: blur(6px);
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ResetPassworld-form{
    background-color: #ffffff;
    padding: 50px ;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.ResetPassworld-div{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    margin: 10px 0;
    .formInput_eyeBox .fas{
        left: 1rem;
        top: 42px;
    }
    .popupform__right-login-img {
        top: 39px;
    }
}
.ResetPassworld-label{
    font-size: 15px;
    margin-bottom: 10px;
}
.ResetPassworld-input{
    padding:8px 25px;
    border: 1px solid #43aa8b;
    border-radius: 3px;
    width: 40rem;
    text-align: right;
    background: #eaeaea6b;
    &:focus{
        outline: none;
        border: 1px solid #43aa8b;

    }
}
.ResetPassworld--logo{
   align-self: flex-end;
    margin: 20px 0;
}