.course-card {
  // border: 1px solid $color-grey-light-1;
  background-color: white;
  width: 80%;
  direction: ltr;
  overflow: hidden;
  box-shadow: 0px 0px 11px 3px #f1eded;
  &:not(:first-child),
  &:not(:last-child) {
    margin: 0 5rem;
  }
  @include respond(tab-port) {
    width: 76%;
    margin: 0 1rem;
  }
  @include respond(big-phone) {
    width: 50%;
    margin: 0 1rem;
  }
  @include respond(phone) {
    width: 50%;
    &:not(:first-child),
    &:not(:last-child) {
      margin: 3rem 0;
    }
  }
  @include respond(small-phone) {
    width: 70%;
    margin: 0 auto 5rem auto;
  }
  @include respond(smallest-phone) {
    width: 60%;
    margin: 0 auto 5rem auto;
  }
  a {
    color: $color-black-dark;
    font-size: 1.7rem;
    font-weight: 600;
    @include respond(phone) {
      font-size: 1.2rem;
    }
  }
  &__picture {
    img {
      width: 100%;
      height: 200px;
      @include respond(tab-port) {
        width: 100%;
      }
      @include respond(phone) {
        width: 100%;
      }
      @include respond(small-phone) {
        width: 100%;
      }
      @include respond(smallest-phone) {
        width: 100%;
      }
    }
  }
  &__content {
    text-align: right;
    padding: 1rem 1rem;
  }
  &__title {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 1.1rem 1rem 1.5rem 1rem;
    span {
      font-size: 1.5rem;
      color: $color-grey-dark;
      margin: 0 5px;
      img {
        width: 2.5rem;
      }
    }
  }
  &__description {
    font-size: 1.6rem;
    color: $color-grey-light-3;
  }
  &__details {
    font-size: 1.7rem;
    color: $color-grey-light-4;

    & > * {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
.rateBox {
  margin: 0 1rem 0.9rem 1rem;
  display: flex;
  flex-direction: row-reverse;
  .rate {
    display: flex;
    flex-direction: row-reverse;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  .fas {
    font-size: 1.5rem;
    color: $color-yellow;
    margin-left: 0.8rem;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  img {
    width: 1.7rem;
    margin-left: 1rem;
    @include respond(phone) {
      width: 1rem;
    }
  }
}
.timeBox1 {
  margin: 0 1rem 0.9rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.timeBox {
  margin: 0 1rem 1.5rem 1rem;
}
.number {
  margin-right: 1.05rem;
  display: flex;
  flex-direction: row-reverse;
  @include respond(phone) {
    font-size: 1rem;
  }
  &::before {
    content: "|";
    width: 1px;
    height: 100%;
    color: $color-grey-light-4;
    margin-left: 1.05rem;
    @include respond(phone) {
      height: 90%;
    }
  }
}
.bookmark {
  display: flex;
  .fa-bookmark {
    font-size: 2.5rem;
    color: $color-orange-dark-1;
    cursor: pointer;
    @include respond(phone) {
      font-size: 2rem;
    }
  }
  .fa-bookmark1 {
    cursor: not-allowed;
  }
}
.time {
  display: flex;
  flex-direction: row-reverse;
  @include respond(phone) {
    font-size: 1rem;
  }
  .far {
    color: $color-green-dark-1;
    margin-left: 1rem;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
}
.shop {
  display: flex;
  padding: 0.5rem;
}
.shoplist {
  background-color: $color-pink-dark;
  outline: none;
  border: none;
  margin-right: 1rem;
  border-radius: 3rem;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 8px;
  font-size: 1.5rem;
}
.price {
  color: #323232;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;

  cursor: pointer;
  @include respond(phone) {
    font-size: 1rem;
  }
  span {
    margin: 0 3px;
  }
}
.price-box {
  @include respond(phone) {
    font-size: 1rem;
  }
  input {
    border: none;
    font-size: 1.9rem;
    color: $color-black-dark;
    direction: rtl;
    text-align: left;
    font-weight: bold;
    width: auto;
    &:focus {
      outline: none;
    }
  }
}
.last-price {
  color: $color-green-dark-2;
  font-size: 1.2rem;
  text-decoration: line-through;
}
.price-Off {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  top: 13px;
  left: -9px;
  @include respond(tab-port) {
    font-size: 1rem;
    top: 8px;
    left: -12px;
  }
  p {
    text-decoration: line-through;
  }
  button {
    border: none;
    background-color: #43aa8b;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    text-align: center;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  input {
    border: none;
    font-size: 1.7rem;
    color: $color-grey-light-4;
    direction: ltr;
    text-align: left;
    font-weight: normal;
    width: 8rem;
    &:focus {
      outline: none;
    }
  }
}
.price-Off1 {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  position: absolute;
  top: 20px;
  left: -3px;
  z-index: 1000;
  @include respond(phone) {
    font-size: 1rem;
  }
  p {
    text-decoration: line-through;
  }
  button {
    border: none;
    background-color: #43aa8b;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    text-align: center;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  input {
    border: none;
    font-size: 1.7rem;
    color: $color-grey-light-4;
    direction: ltr;
    text-align: left;
    font-weight: normal;
    width: 8rem;
    &:focus {
      outline: none;
    }
  }
}

.newtag {
  color: white;
  text-align: left;
  font-size: 1.3rem;
  background-color: $color-green-dark;
  padding: 3px 6px;
  width: max-content;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: -5px;
  left: 2rem;
}

.level {
  display: flex;
  flex-direction: row-reverse;
  font-size: 1.7rem;
  //   margin-bottom: 1.5rem;
  margin: 0 0.5rem 1.5rem 1rem;
  @include respond(phone) {
    font-size: 1.2rem;
  }
  & > *:last-child {
    margin-right: 0.5rem;
  }
  color: $color-grey-light-4;
  & > *:first-child {
    color: $color-black-dark;
  }
}
.price-box {
  padding: 10px 15px;
  border: 2px solid #90be6d;
  border-radius: 30px;
  display: flex;
  align-items: center;
}
.price-box1 {
  padding: 0.5rem 1rem;
  border: 2px solid #90be6d;
  border-radius: 30px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-left: 1rem;
}
.price__box {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  margin-top: -41px;
  margin-bottom: 11px;
  margin-left: 5px;
}
.price__box1 {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  position: relative;
  margin-top: -17px;
  margin-bottom: 0rem;
  margin-left: 5px;
}
.real__cost {
  font-size: 1.4rem;
  margin-left: 5rem;
  margin-bottom: 5px;
}
.real__cost1 {
  font-size: 1.4rem;
  margin-left: 1rem;
  text-align: center;
  margin-bottom: 5px;
}
