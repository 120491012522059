.search-sidebar {
  border: 1px solid $color-grey-light-1;
  border-radius: 2rem;
  text-align: right;
  margin: 1rem 0;
  background-color: white;
  & > :not(:last-child) {
    border-bottom: 1px solid $color-pink-light;
  }
}
.search-form {
  display: flex;
  position: relative;
  &-input {
    padding: 1.5rem 2rem;
    border-radius: 5rem;
    outline: none;
    border: 1px solid $color-pink-light;
    background-color: $color-grey-light-2;
    text-align: right;
    width: 100%;
    margin: 1rem;
    position: relative;
  }
  &-icon {
    position: absolute;
    transform: translate(31%, 34%);
    outline: none;
    background-color: $color-grey-light-2;
    border: 1px solid $color-pink-light;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-left: 2px;
    i {
      font-size: 2rem;
      color: $color-grey-dark;
    }
  }
}
.tag {
  display: flex;
  flex-direction: row-reverse;
  list-style: none;
  font-size: 1.2rem;
  color: $color-grey-dark;
  align-items: center;

  &__list {
    margin: 1rem 0;
    padding: 0 1.5rem;

    &:not(:last-child) {
      border-left: 2px solid -light;
    }
  }
}
.SidebarItem__list {
  &--text {
    list-style: square;

    li {
      margin-top: 1rem;
      margin-right: 1rem;
      font-size: 1.4rem;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      i {
        margin-left: 5px;
      }
    }
  }
}
