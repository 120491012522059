.EmailVerify{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    animation: zoomOut 0.6s ease-in-out 0.1s  ;
    animation-iteration-count:infinite;
    h1{
        color: rgb(30, 143, 16);
        font-size: 4rem;
    }
}
@keyframes zoomOut {
    from{
        transform: scale(1);
        
    }
    to{
        transform: scale(1.2);
    }
}