.Card-container-sec{
margin-top: 10rem;
min-height: 84vh;
background-image: url("../../img/sabadcala/Path\ 284.svg");
background-size: cover;
@include respond(big-phone) {
    margin-top: 23rem;

  }
}
.Card-Container{
display: grid;
grid-template-columns: 1fr 3fr;
grid-gap: 2rem;
@include respond(big-phone) {
    grid-template-columns: 1fr ;
  }

}
.Card-sidebar{
 
}

.Card-box{
    background-color:#FFFFFF;
    border: none;
    border-radius: 2rem;
    margin: 2rem 0;
    border: 1px solid #cbd0d3;
    &-title{
        background: #43aa8b;
    padding: 5px;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
        // padding: 0.2rem 0.5rem;
        // border-bottom: 1px solid $color-grey-light-3;
        text-align: center;
        color: $color-white;
        font-size: 1.5rem;
    }
}
.btn-big{
    padding: 1.5rem;
    width: 100%;
    background-color: $color-green-dark;
    outline: none;
    border: 1px solid $color-grey-light-3;
    border-radius: 2rem;
    color: #fff;
    // font-family:'Samim', sans-serif;
    font-size: 2rem;
    font-weight: 500;
    &:focus{
        outline: none;
    }
}