.Pagination {
  @include respond(phone) {
    width: 100%;
  }
  .MuiPaginationItem-root {
    // color: $color-green !important;
    font-size: 1.5rem;
    @include respond(phone) {
      font-size: 1rem;
      margin: 0;
      // min-width: 3rem;
    }
  }
  .MuiSvgIcon-root {
    // color: $color-green;
    font-size: 1.5rem;
    @include respond(phone) {
      font-size: 1rem;
    }
  }
  .MuiPaginationItem-page.Mui-selected {
    // background-color: $color-green-light-1;
    // color: $color-green-light;
  }
  .MuiPaginationItem-rounded {
    border-radius: 50%;
  }
}
