.ContentBody {
  width: 100%;
  // padding-right: 10rem;
  // padding-left: 12rem;
  // direction: rtl;
  font-size: 1.65rem;
  font-weight: normal;
  position: relative;
  &__Container {
    margin-bottom: 2rem;
  }
  &__codtxt {
    margin-bottom: 2rem;
  }
  &__Example {
    clear: both;
    margin-bottom: 1rem;
    border: 1px solid #86b7c8;
    border-radius: 3rem;
    // padding: 1rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    .fa-link {
      background-color: #86b7c8;
      padding: 1.5rem;
      border-radius: 50%;
      font-size: 1.5rem;
      color: #fff;
    }
    P {
      color: #86b7c8;
      font-size: 1.2rem;
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.CodeEditotBox {
  // width: 50rem;
  float: left;
  height: 50vh;
  margin-right: 5rem;
  margin-top: 0rem;
  margin-bottom: 3rem;
  @include respond(tab-land) {
    width: 100%;
  }
}

.ContentBodyText {
  line-height: 2;
  direction: rtl;
  text-align: justify;
  margin-bottom: 3rem;
  & > ::before {
    content: "\a";
    white-space: pre;
  }
}
.ContentBodyText1 {
  clear: both;
  line-height: 2;
  direction: rtl;
  text-align: justify;
  margin-bottom: 3rem;
  & > ::before {
    content: "\a";
    white-space: pre;
  }
}
.CodeEditotBoxcenter {
  clear: both;
  margin: 5rem auto;
  position: relative;
  left: 50%;
  transform: translateX(-25%);
}
