@import './variable';
@import './mixin';
@import './mixins.scss';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  font-family: 'Samim', sans-serif;
  @include respond(tab-land) {
    font-size: 56.25%; //1rem=9px
  }
  @include respond(tab-port) {
    font-size: 50%; //1rem=8px
  }
  @include respond(smallest-phone) {
    font-size: 50%;
  }
  @include respond(big-desktop) {
    font-size: 80%;
  }
  @include respond(bigest-desktop) {
    font-size: 100%;
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Vazir', sans-serif !important;
}
button {
  font-family: 'Vazir', sans-serif !important;
}
select {
  font-family: 'Vazir', sans-serif !important;
}
.list {
  list-style: none;
}
a,
a:link,
a:active {
  text-decoration: none;
}
p::selection {
  background: $color-green-dark-2;
  color: white;
}

.container {
  max-width: 120rem;
  overflow: hidden;
  margin: 0 auto;
  padding: 0 2rem;
  @include respond(tab-port) {
    width: 100%;
    padding: 0;
  }
  @include respond(phone) {
    width: 100%;
  }
  @include respond(small-phone) {
    width: 100%;
  }

  @include respond(smallest-phone) {
    width: 100%;
  }
  @include respond(big-desktop) {
    max-width: 250rem;
    padding: 0 5rem;
  }
}

.property-sec {
  padding: 10rem 2rem;
  padding-top: 20rem;
  background-image: url('../img/svg/Path\ 296.svg');
  background-size: cover;

  background-color: $color-grey-light-2;
  margin-top: -1px;
  @include respond(phone) {
    background-position: center;
  }
}
.last-sect {
  background-image: url('../img/svg/Path\ 297.svg');
  background-size: cover;
  padding-top: 18rem;

  margin-top: -1px;
  @include respond(phone) {
    background-position: center;
  }
}

.center {
  @include center;
}
.d-flex {
  display: flex;
  align-items: center;
}
.centermargin {
  margin: 0 auto;
}
.column {
  flex-direction: column;
  align-items: center;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.feedback {
  padding: 2rem;
  background-color: $color-grey-light-2;
}
button,
input {
  //  font-family: "Samim", sans-serif;
}
.error-p {
  color: #fe7f2d !important;
  font-size: 1.2rem;
  margin-right: 40px;
}
.error-border {
}
