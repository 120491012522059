.email__form-sec{
    background-color: $color-green-dark-2;
    padding: 4rem 1rem;
    
    .email__form{
        @include center;
        flex-direction: column;
        &--title{
          font-size: 1.6rem;
        }
        &--form{
            width: 28rem;
            @include center;
            flex-direction: column;
            &-input{
                background-color: transparent;
                border: none;
                outline: none;
                border-bottom: 1px solid $color-grey-light-3;
                margin: 3rem 0;
                width: 100%;
                padding: 5px 0;
                &::placeholder{
                    color: $color-grey-light-3;
                    text-align: center;
                    font-weight: 400;
                }
                &:focus{
                    outline: none;
                }

            }
        }
    }
}