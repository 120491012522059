.SarfaslContent {
  & > * {
    margin-bottom: 2rem;
  }
}
.SarfaslContent__contentTxt {
  color: $color-black-light;
  font-size: 12px;
  float: left;
  text-align: right;
  width: 90%;
  line-height: 2.3rem;
  margin: 3rem auto 0 auto;
  float: none;
  & > ::before {
    content: '\a';
    white-space: pre;
  }
  @include respond(phone) {
    padding-right: 13.5rem;
    padding-left: 3rem;
    width: 85%;
  }
  &-down {
    padding-bottom: 3rem;
    border-bottom: 1px solid $color-grey-dark1;
    width: auto;
    margin-left: 7rem;
    margin-right: 7rem;
  }
}
.SarfaslContentviedeo__box {
  width: 70.6rem;
  height: 30rem;
  border-radius: 3rem;
  background-color: $color-pink-light;
  margin: 0 auto;
  position: relative;
}

.CorseIntro__video-Season {
  font-size: 1.2rem;
  font-weight: 400;
  color: $color-white;
  position: absolute;
  z-index: 5;
  top: 0.85rem;
  right: 21rem;
  &::before {
    content: '<';
    position: absolute;
    font-size: 1.5rem;
    top: -0.25rem;
    right: -1rem;
  }
}
.CorseIntro__video-Lesson {
  font-size: 1.2rem;
  font-weight: 400;
  color: $color-white;
  position: absolute;
  z-index: 5;
  top: 0.85rem;
  right: 27.5rem;
  &::before {
    content: '<';
    position: absolute;
    font-size: 1.5rem;
    top: -0.25rem;
    right: -1rem;
  }
}

$animation-duration: 1.8s;

.SarfaslContent__headFirstLine {
  width: 40%;
  height: 5rem;
  @include respond(phone) {
    width: 40%;
    height: 5rem;
  }
}
.SarfaslContent__headSecLine {
  width: 40%;
  height: 5rem;
  @include respond(phone) {
    width: 40%;
    height: 5rem;
  }
}

.SarfaslPlayerBox {
  text-align: center;
  position: relative;
  width: 90%;
  margin: 0 auto;
  @include respond(phone) {
    // margin-left: 6rem;
  }
}
.DarsContentAccordion{
  padding-left:  0 !important; 
  padding-right:  0 !important; 

}

.accordion__content-box  { 
   margin: 0.5rem 0 0 0;}