.TeachersBox {
  display: flex;
  align-items: center;
  margin-top: 6rem;
  flex-direction: row-reverse;
  justify-content: center;
  margin-right: 7.5rem;
  margin-left: 3rem;

  &__Table {
    border: 1px solid $color-yellow;
    border-radius: 3rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: end;
    padding: 3rem 4.5rem 3rem 5rem;
    width: 70%;
    margin-left: 30px;
    h5 {
      font-size: 2.2rem;
      margin-bottom: 1.4rem;
    }
    p {
      font-size: 1.2rem;
      line-height: 2.3rem;
    }
  }
  &__Btn {
    padding: 1rem 2.8rem 1rem 2.8rem;
    font-size: 1.2rem;
    color: $color-white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2rem;
    align-self: flex-start;
    .fas {
      font-size: 1.5rem;
      margin-right: 0.81rem;
    }
  }
  &__img {
    width: 170px;
    height: 154px;
  
  }
}
