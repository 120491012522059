.header {
  height: 90vh;
  z-index: 1000;
  position: relative;
  padding-bottom: 10rem;

  @include respond(small-phone) {
    height: 68vh;
    // width: auto;
  }

  .showcase {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: end;
    padding: 4rem 0;
    align-items: center;
    margin-top: 60px;
    @include respond(tab-port) {
      margin-right: 15px;
    }
    @include respond(big-phone) {
      margin-top: 91px;
    }
    @include respond(phone) {
      padding: 2rem 0;
      display: flex;
      margin-right: 1rem;
      margin-left: 1rem;
    }
    &-text {
      font-size: 2rem;
      color: $color-grey-light-4;
      width: 80%;
      text-align: right;
      @include respond(tab-port) {
        width: 85%;
        // margin-right: 4rem;
      }
      @include respond(phone) {
        width: 90%;
        margin-left: 2rem;
      }
    }
    &__pic {
      display: flex;

      img {
        width: 106%;
        margin: 0 auto;
        @include respond(tab-port) {
          width: 92%;
        }
        @include respond(big-phone) {
          width: 100%;
        }
        @include respond(phone) {
          width: 100%;
        }
      }
    }
    &__pic1 {
      display: flex;
      padding: 10rem 0 4rem 0;

      img {
        width: 50%;
        margin: 0 auto;
        @include respond(tab-port) {
          width: 92%;
        }
        @include respond(big-phone) {
          width: 100%;
        }
        @include respond(phone) {
          width: 100%;
        }
      }
    }

    &__content {
      display: grid;
      grid-gap: 2rem;
      justify-items: flex-end;
      @include respond(tab-port) {
        // width: 85%;
        margin-right: 0;
      }
      @include respond(phone) {
        display: flex;
        flex-direction: column;
        width: 28rem;
      }
      h1 {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include respond(tab-land) {
          width: 100%;
        }
        @include respond(phone) {
        }
      }

      .items {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-template-rows: repeat(2, max-content);
        justify-content: flex-end;
        grid-gap: 1.5rem;
        @include respond(phone) {
          grid-gap: 0.5rem;
          grid-template-columns: repeat(1, max-content);
        }
      }

      .item {
        color: $color-grey-light-4;
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        align-items: center;
        font-size: 2rem;
        width: 100%;
        @include respond(phone) {
          font-size: 1.4rem;
        }

        .svg i {
          font-size: 1.8rem;
          color: $color-green-dark-2;
          border: 2px solid $color-green-dark-2;
          border-radius: 50%;
          box-sizing: content-box;
          padding: 0.5rem;
          margin-left: 1rem;
          @include respond(phone) {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .left-arrow {
    display: flex;
    align-items: center;
    i {
      margin: 0 5px;
    }
  }
  .arrow {
    @include center;
    padding: 2rem;

    @include respond(big-phone) {
      padding: 0;
    }
    @include respond(small-phone) {
      margin-top: -1rem;
    }

    img {
      width: 2rem;
    }
  }
}
.ShowCase__BtnBox {
  // padding: 0.9rem 2.3rem 1.6rem 2rem;
  margin-top: 3.1rem;
  @include respond(phone) {
    width: 15rem;
    margin-top: 1rem;
    margin-left: 13rem;
  }
}
.ShowCase__Btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include respond(phone) {
  }
  .fas {
    margin-right: 1.8rem;
    font-size: 2rem;
    @include respond(phone) {
      margin-right: 1rem;
      font-size: 1.2rem;
    }
  }
  p {
    font-size: 2rem;
    white-space: nowrap;

    @include respond(phone) {
      font-size: 1.5rem;
    }
    @include respond(small-phone) {
    }
  }
}
.arrow__down {
  display: flex;
  justify-content: center;
  font-size: 3.5rem;
  margin-top: 0.5rem;
  color: #3e9a77;
}
.header.headercourselist {
  height: 70vh;
}
.Toastify__toast-container {
  z-index: 9999999999 !important;
  -webkit-transform: translate3d(0, 0, 9999px);
  position: fixed;
  padding: 4px;
  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
.Toastify__toast-container--top-center {
  top: 10em !important;
  left: 50%;
  transform: translateX(-50%);
}
