.PhoneTeacherBox {
  // margin-top: 2rem;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // position: relative;
  &__Table {
    width: 100%;
    margin: 0 auto;
    @include respond(tab-land) {
      width: 70rem;
    }
    @include respond(tab-port) {
      width: 55rem;
    }
    @include respond(smallest-phone) {
      width: 94%;
    }
  }
  &__teachPhoto {
    position: absolute;
    top: 0.5rem;
    left: 0rem;
    width: 10rem;
    height: auto;
    @include respond(tab-land) {
      top: 0.5rem;
      left: 0%;
      width: 20rem;
    }
    @include respond(tab-port) {
      top: 0.5rem;
      left: 0%;
      width: 14rem;
    }
    @include respond(smallest-phone) {
      top: 0.5rem;
      left: 5%;
      width: 14rem;
    }
  }
  &__teachInfo {
    // position: absolute;
    // top: 6rem;
    // right: 8.5rem;
    // width: 44%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @include respond(tab-land) {
      top: 10rem;
      right: 24%;
      width: 50%;
    }
    @include respond(tab-port) {
      top: 9rem;
      right: 25%;
      width: 45%;
    }
    @include respond(smallest-phone) {
      top: 7rem;
      right: 15%;
      width: 45%;
    }
    h5 {
      font-size: 1.5rem;
      font-weight: 100;
      color: $color-black-dark-2;
      @include respond(tab-land) {
        font-size: 3rem;
      }
      @include respond(tab-port) {
        font-size: 2rem;
      }
      @include respond(smallest-phone) {
        font-size: 2.5rem;
      }
    }
    p {
      margin-top: 0.4rem;
      color: $color-black-dark;
      font-size: 1.2rem;
      direction: rtl;
      line-height: 1.9rem;
      @include respond(tab-land) {
        margin-top: 6rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
      @include respond(tab-port) {
        margin-top: 1.4rem;
        font-size: 1.6rem;
        line-height: 2.5rem;
      }
      @include respond(smallest-phone) {
        font-size: 1.8rem;
        margin-top: 0.8rem;
        line-height: 2.5rem;
      }
    }
  }
}
.PhoneTeacherBox__container {
  border: 1px solid #90be6d;
  border-radius: 15px;
  img.PhoneTeacher__profile {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    position: absolute;
    left: 30px;
    top: -65px;
  }
}
.half__circle {
  width: 150px;
  height: 150px;
  border: 1px solid #90be6d;
  border-radius: 50%;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: rotate(-45deg);
  background: #ffffff;
  position: absolute;
  top: -75px;
  left: 20px;
}
.PhoneTeacher__info {
  padding: 33px;
  text-align: right;
  font-size: 14px;
  margin-top: 30px;
  h5{
    font-size: 2.1rem;
    transform: translateY(-24px);
  }
  p{
    text-align: justify;
    direction: rtl
  }
}
.PhoneTeacher {
  margin: 105px 36px 10px 36px;
}