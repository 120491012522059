.accardion__section {
  display: flex;
  flex-direction: column;
  text-align: right;
  &:not(:last-child) {
    border-bottom: 1px solid $color-pink-light;
  }
}

.accardion {
  color: $color-grey-dark;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  .chevron-icon i {
    font-size: 1.8rem;
  }
}
.accardion__content {
  background-color: #fff;
  overflow: hidden;
  transition: max-height 0.6s ease;
  .accardion__text {
    color: $color-grey-dark;
    font-size: 1.6rem;
  }
}
