.PhoneCurseTable {
  border: 1px solid $color-yellow;
  border-radius: 2rem;
  width: 83%;
  &__firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 1.6rem 0 1.6rem;
    p {
      font-size: 1.4rem;
      font-weight: 500;
    }
    .far,
    .fas {
      color: $color-orange-dark-1;
      font-size: 2rem;
    }
    .far {
      margin-right: 1.6rem;
    }
  }
  &__SecRow {
    padding: 1.3rem 1.6rem 0 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond(smallest-phone) {
      padding: 1.8rem 1.6rem 0 1.2rem;
    }
    img {
      width: 3rem;
      height: 2.4rem;
    }
    &-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 0.5rem;
      & > *:first-child {
        font-size: 1.4rem;
        color: $color-black-dark;
      }
      & > *:last-child {
        font-size: 1.2rem;
        color: $color-grey-light-4;
        margin-top: 0.3rem;
      }
    }
    select {
      direction: rtl;
      align-items: center;
      color: $color-orange-dark-1;
      font-size: 1.3rem;
      font-weight: 500;
      width: auto;
      border: none;

      &:focus {
        outline: none;
      }
    }
    select:focus > option:checked {
      background-color: rgba($color-orange-dark-1, 0.3);
    }
  }
  &__ThirdRow {
    padding: 0.7rem 1.6rem 0 1.6rem;
    display: flex;
    flex-direction: row-reverse;
    @include respond(smallest-phone) {
      padding: 1.2rem 1.6rem 0 1.6rem;
    }
    p {
      font-size: 1.2rem;
    }
    & > *:first-child {
      color: $color-black-dark;
    }
    & > *:last-child {
      color: $color-grey-light-4;
      margin-right: 0.3rem;
    }
  }
  &__ForthRow {
    padding: 0.7rem 1.6rem 0 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    @include respond(smallest-phone) {
      padding: 1.2rem 1.6rem 0 1.6rem;
    }
    & > *:first-child {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      img {
        width: 1.5rem;
        height: 1.3rem;
        margin-right: 0.25rem;
      }
      & > *:first-child {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
      & > *:last-child {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
      }
      .fas {
        color: $color-yellow;
        font-size: 1.2rem;
      }
      p {
        color: $color-grey-light-4;
        font-size: 1.2rem;
        margin-right: 0.6rem;
      }
    }
    & > *:last-child {
      display: flex;
      align-items: center;
      p {
        color: $color-grey-light-4;
        text-decoration: line-through;
        font-size: 1.2rem;
        margin-left: 0.5rem;
      }
      button {
        color: $color-white;
        font-size: 1.3rem;
        font-weight: bold;
        border: none;
        background-color: $color-green-dark-2;
        padding: 0.2rem 0.3rem;
        border-radius: 3rem;
        &:focus {
          outline: none;
        }
      }
    }
  }
  &__Line {
    width: 1px;
    height: 1.5rem;
    background-color: $color-grey-light-4;
    margin-right: 0.25rem;
  }
  &__LastRow {
    padding: 0.55rem 1.6rem 1.8rem 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include respond(smallest-phone) {
      padding: 1.2rem 1.6rem 1.8rem 1.6rem;
    }
    & > *:first-child {
      color: $color-black-dark;
      font-size: 1.4rem;
      display: flex;
      align-items: center;
      span {
        margin-right: 0.2rem;
      }
    }
    & > *:last-child {
      display: flex;
      align-items: center;
      p {
        color: $color-grey-light-4;
        font-size: 1.2rem;
        margin-right: 0.4rem;
      }
      .far {
        color: $color-green-dark-1;
        font-size: 1.2rem;
      }
    }
  }
}
