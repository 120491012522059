
// .active-slide-0 #card-1{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-1 #card-2{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-2 #card-3{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-3 #card-4{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-4 #card-5{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-5 #card-6{
//   filter: blur(0);
//     transform: scale(1);
// }
// .active-slide-6 #card-7{
//   filter: blur(0);
//     transform: scale(1);
// }
.card{
    width: 30rem;
    padding: 2rem;
    border: 1px solid $color-green-dark;
    border-radius: 3rem;
    background-color: $color-white;
  
    transform: scale(1);
    transition: all 0.6s ease-in-out;
    .user__comment{
        font-size: 1.4rem;
        text-align: right;
        padding-bottom: 8rem; 
    }
    .user__details{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .user-image{
            width: 5rem;
          
        }
        .user-info{
            text-align: right;
            margin: 0 1rem;
            font-size: 1.3rem;
        }
        .user-name{
            margin: 5px 0;

        }

    }

}
  .cards-slider-wrapper {
    display: flex;
   
    transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955); }
  .cards-slider-wrapper .card {
    flex: 1;
    min-width: 33rem;
    opacity: 1;
    margin-right: 3rem;
    margin-left: 1rem;
    transform: scale(1);
    transition: opacity 300ms linear, border-color 300ms linear, background-color 300ms linear, transform 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955); 
    @include respond(tab-land){
     min-width: 32rem ;
  };
  @include respond(tab-port){
    min-width: 28rem ;
 };
  }
    .cards-slider-wrapper .card .details {
    transition: opacity 150ms linear;
}
.feedabacklist-btnBox{
    position: relative;
 
  button{
      background-color: rgba($color-green-dark,0.5);
      outline: none;
      border: none;
      padding: 1rem;
      margin: 5px;
      border-radius: 10px;
      i{
          font-size: 2rem;
          color: #fff;
      }
      &:hover{
        background-color: $color-green-dark ;
      }
  }
    }
    .feedabacklist-sec{
      background-color: $color-grey-light-1;
      padding: 3rem;
      position: relative;
      margin-bottom: 15rem;
      &::before{
        content: "";
        background-color: #ebeded;
        position: absolute;
        bottom: -104px;
        left: -215px;
        width: 178rem;
        height: 100%;
        border-radius: 50%;
        @include respond(tab-port){
          width: 160rem;
       };
      }
    }
