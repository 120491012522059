.form {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  align-content: center;
  grid-gap: 2rem;
  padding: 2rem 17rem;
  @include respond(phone) {
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  @include respond(tab-port) {
    padding: 2rem 6rem;
  }

  &-input {
    border: none;
    border: 1px solid $color-grey-dark;
    width: 100%;
    text-align: right;
    outline: none;
    position: relative;
    //background-color: inherit;
    border-radius: 5px;
    padding: 0 2rem;

    &:focus {
      outline: none;
      border: 1px solid $color-green-dark-2;
    }
    &:invalid {
      outline: none;


    }
  }

  &-select {
    border: 1px solid #a6adb4;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 4rem;
    color: $color-green-dark-2;
    font-size: 1.2rem;
    border-radius: 5px;
    @include respond(phone) {
      line-height: 2rem;
    }

    svg {
      fill: $color-green-dark-2;
      width: 2rem;
      height: 2rem;
    }

    select {
      border: none;
    }
  }

  &-desc {
    border: none;
    border: 1px solid $color-grey-dark;
    width: 100%;

    grid-column: 1/-1;
    outline: none;
    text-align: start;
    direction: rtl;
    //background-color: inherit;
  }

  .center {
    grid-column: 1/-1;
    button {
      padding: 1rem;
      outline: none;
      border: none;
      transition: background-color ease-out 0.1s, border 0.1s linear 0.3s;

      &:hover {
        background-color: $color-white;
        border: 2px solid $color-green-dark-2;
        color: $color-green-dark-2;
      }
    }
  }
}
