.Card-Course-row {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.delete-icon {
  font-size: 2rem;
  color: $color-grey-light-3;
  transform: translateX(-1rem);
  cursor: pointer;
}
.Card-Course {
  background-color: #fff;
  border: 1px solid $color-grey-light-3;
  border-radius: 2rem;
  margin: 2rem;
  height: 10rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 2fr 1fr;
  padding: 1rem;
  text-align: right;
  align-content: center;
  flex: 1;
  & > *:nth-child(2),
  & > *:nth-child(3),
  & > *:nth-child(4) {
    align-self: center;
    justify-self: center;
  }
}
.dialog-btn-box {
  @include center;
  margin: 2rem 0;
  button {
    border-radius: 1.8rem;
    margin: 0 0.5rem;
    font-family: 'Samim', sans-serif;
  }
  .btn-yes {
    background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
  }
}
.card-dialog-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  background-color: #b1afafc9;
  top: 0;
  left: 0;
  z-index: 10000;
}
.card-delete-dialog {
  @include center;
  flex-direction: column;
  border: 1px solid $color-grey-light-3;
  padding: 4rem;
  border-radius: 1rem;
  position: fixed;
  width: 20%;
  background: #fff;
  height: 18%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.6rem;
  p {
    margin-top: 2rem;
  }
}
.Card-cost {
}
.Card-madrak {
}
.btn-no{
  background: #aba5a5;
}
.btn-no,
.btn-yes {
  width: 70px;
  color: $color-white;
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  border: none;
  &:focus {
    outline: none;
  }
  &:hover {
    //  transform: translateY(-3px);
    transform: scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.4);
  }
}
