.UserDetails {
  h1 {
    overflow: hidden;
    text-align: center;
    color: $color-grey-light-4;
    font-size: 1.8rem;
    font-weight: bold;
  }
  h1:before,
  h1:after {
    background-color: $color-grey-dark1;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }
  h1:before {
    right: 6rem;
    margin-left: -50%;
  }
  h1:after {
    left: 6rem;
    margin-right: -50%;
  }
}
.UserDetails__dataTopBox {
  display: flex;
   align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  width: 95%;
  margin: 1rem auto;
  @include respond(tab-port) {
    flex-direction: column;


  }
  & > *:not(:first-child) {
    margin-right: 3rem;
    @include respond(tab-port) {
      margin-right: 0;
  
  
    }
  }
}
.UserDetails__selectinput {
  width: 80%;
  display: flex;
  justify-content: center;
}
.UserDetails__dataTopBox1 {
  display: flex;
  // align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  width: 50%;
  margin: 1rem auto;
  & > *:not(:first-child) {
    margin-right: 3rem;
  }
}
.UserDetails__ErrinputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-top: 2rem;
  width: 100%;
  // margin: 1rem auto;
  input,
  select {
    background-color: transparent;
    border: 1px solid $color-orange-dark-1;
    border-radius: 0.5rem;
    padding: 1.1rem 3rem;
    text-align: end;
    color: $color-black-dark;
    margin-top: 0.3rem;
    width: 100%;
    font-size: 1.5rem;
    &:focus {
      outline: none;
    }
  }
  select {
    direction: rtl;
  }
}
.UserDetails__inputBox {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  margin-top: 2rem;
  width: 100%;
  // margin: 1rem auto;
  input,
  select {
    background-color: transparent;
    border: 1px solid $color-black-dark;
    border-radius: 0.5rem;
    padding: 1.1rem 3rem;
    text-align: end;
    color: $color-black-dark;
    margin-top: 0.3rem;
    width: 100%;
    font-size: 1.5rem;
    &:focus {
      outline: none;
    }
  }
  select {
    direction: rtl;
  }
}
.newGrouperrorMsg {
  color: $color-orange-dark-1;
}

.UserDetails__BtnSecondary {
  text-align: center;
  margin-top: 3rem;
}
.UserDetails__label {
  text-align: right;
  margin: -50px 25px !important;
}
.UserDetails__selectinput select {
  width: 100%;
  padding: 5px 25px;
  direction: rtl;
  outline: none;
  border: 1px solid #a6adb4;
  border-radius: 5px;
}
.imagePreview{
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.UserDetails--uploadcover--container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
input#image__cover--perview {
  opacity: 0;
  display: none;
}
label.image__cover--perview-label {
  font-size: 14px;
  padding: 5px 8px;
  margin: 15px 0;
  border: 2px solid #71bc9c;
  background: #ffffff;
  border-radius: 22px;
  transition: 0.5s;
  &:hover{
    background-color: #71bc9c;
    color: #fff;
  }
}
.UserDetails--uploadcoverbox {
  margin-top: 13px;
  margin-bottom: 15px;
}
.rmdp-container {
  width: 100%;
}