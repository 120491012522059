.showcourses {
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  grid-auto-rows: max-content;
  margin: 1rem;
  margin-right: 1rem;
  // align-items: center;
  @include respond(tab-port) {
    align-self: center;
    justify-self: center;
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(big-phone) {
    display: flex;
    justify-content: space-between;
  }
  @include respond(phone) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @include respond(tab-port) {
  }
  @include respond(big-desktop) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 2rem;
    grid-auto-rows: max-content;
    margin: 1rem;
    margin-right: 1rem;
  }
}
