.PriceSection {
  padding: 0 0rem 0 4rem !important;
  display: flex;
  align-items: center;
  // @include respond(phone) {
  //   padding: 0 0.5rem 0 0.5rem !important;
  // }
}
.PriceSection1 {
  padding: 0 2rem 0 2rem;
  display: flex;
  align-items: center;
  // @include respond(phone) {
  //   padding: 0 0.5rem 0 0.5rem !important;
  // }
}
.PriceSection__firstRow {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 0rem;
  overflow: hidden;
  // padding: 0 2rem 0 2rem;
  select {
    direction: rtl;
    align-items: center;
    color: $color-orange-dark-1;
    font-size: 1.2rem;
    font-weight: 500;
    // width: 8rem;
    border: none;

    &:focus {
      outline: none;
    }
    // @include respond(phone) {
    //   font-size: 1rem;
    //   font-weight: 300;
    //   width: 5rem;
    // }
  }

  select:focus > option:checked {
    background-color: rgba($color-orange-dark-1, 0.3);
  }
  div {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    p {
      font-size: 1.3rem;
      color: $color-grey-light-4;
      margin-left: 1.2rem;
      text-decoration: line-through;
      font-weight: normal;
      // @include respond(phone) {
      //   font-size: 1rem;
      //   margin-left: 0.9rem;
      // }
    }
    button {
      padding: 0.5rem 0.7rem;
      border: none;
      border-radius: 1.8rem;
      text-align: center;
      background-color: $color-green-dark-2;
      color: $color-white;
      font-size: 1.4rem;
      font-weight: 700;
      &:focus {
        outline: none;
      }
      // @include respond(phone) {
      //   padding: 0.45rem 0.6rem;
      //   font-size: 1.2rem;
      //   font-weight: 500;
      // }
    }
  }
}
.PriceSection__SecondRow {
  display: flex;
  align-items: center;
  margin-top: 0rem;
}
.PriceSection__ThirdRow {
  margin-top: 0rem;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.PriceSection__Button {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  border-radius: 3rem;
  padding: 0.6rem 1.03rem 1.3rem 1.3rem;
  margin: 0 auto;
  width: 150px;

  @include respond(phone) {
    padding: 0.6rem 1.03rem 0.5rem 1.3rem;
  }
  // @include respond(phone) {
  //   padding: 0.4rem 1.83rem 1.2rem 2.1rem;
  // }
  p {
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 0.5rem;
    @include respond(phone) {
      font-size: 1rem;
    }
    // @include respond(phone) {
    //   font-size: 1rem;
    //   font-weight: 300;
    // }
  }
  img {
    margin-right: 0.85rem;
    width: 2.2rem;
    height: 2.2rem;
    margin-top: 0.5rem;
    @include respond(phone) {
      width: 1.2rem;
      height: 1.2rem;
    }
    // @include respond(phone) {
    //   width: 1.2rem;
    //   height: 1.2rem;
    //   margin-right: 0.5rem;
    // }
  }
}
.PriceSection__PriceBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.8rem;
  @include respond(phone) {
    font-size: 1.5rem;
  }
  // @include respond(phone) {
  //   font-size: 1rem;
  // }
  span {
    margin-right: 0.5rem;
  }
}
.PriceSection__PriceBox1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.2rem;
  @include respond(phone) {
    font-size: 1rem;
  }
  // @include respond(phone) {
  //   font-size: 1rem;
  // }
  span {
    margin-right: 0.5rem;
  }
}
