.bg-pink {
  background-color: $color-green-dark-2;
  padding: 3rem 0;
  width: 100%;
  p {
    color: #fff;
    font-weight: 500;
    font-size: 1.6rem;
    margin: 2rem 0;
  }
}
.PinkbackSectionBtn {
  font-size: 2rem;
  padding: 0.7rem 3.5rem; 
   border-radius: 3rem;
  margin-top: 2rem;
}

.pinkbac-label {
  span {
    background-color: transparent;
  }
}
.start {
  flex-direction: column;
  align-items: center;
}

.PinkbackSectionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.PinkbackSectionInputBox {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: center;
  label {
    color: $color-white;
  }
  input {
    border: none;
    background-color: transparent;
    border: 1px solid $color-white;
    width: 25rem;
    border-radius: 5px;
    padding: 1rem;
    margin: 1rem 0;
    text-align: right;
    color: #fff;
    &::placeholder {
      text-align: right;
      color: #fff;
    }
    &:focus {
      outline: none;
    }
  }
}
