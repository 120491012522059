@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin respond($breakpoint) {
  @if $breakpoint == smallest-phone {
    @media only screen and (max-width: 25em) {
      //400px
      @content;
    }
  }
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 31.25em) {
      //500px
      @content;
    }
  }
  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint == big-phone {
    @media only screen and (max-width: 43.75em) {
      //700px
      @content;
    }
  }
  @if $breakpoint == biger-phone {
    @media only screen and (max-width: 48em) {
      //900
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      //900
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      //1200
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    //1800
    @media only screen and (min-width: 112.5em) {
      @content;
    }
  }
  @if $breakpoint == bigest-desktop {
    //2800
    @media only screen and (min-width: 175em) {
      @content;
    }
  }
} ;
