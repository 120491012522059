.SimilarCourse {
  // display: flex;
  // align-items: center;
  // justify-content: space-evenly;
  // margin: 5rem 2rem 8rem 2rem;
  direction: rtl;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  grid-auto-rows: max-content;
  margin: 1rem;
  margin-left: auto;
  & > * {
    width: 65%;
  }
}
