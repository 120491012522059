.scroll-element {
  scroll-margin: 2rem 0 0 0;
}
.StickDotBox {
  display: flex;
  flex-direction: column;
  list-style: none;
}
.StickDotList {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.StickDotElement {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.strok {
  stroke: $color-yellow;
  stroke-dasharray: 5;
}
.StickDot {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: $color-yellow;
  border: none;
  margin: 0.5rem auto 0 auto;
  transition: all ease-in-out 0.2s;
}
.StickDotactive {
  &:first-child {
    border: 3px solid $color-orange-dark-1;
    border-radius: 50%;
    width: 3.8rem;
    height: 3.8rem;
  }
  .StickDot {
    background-color: $color-orange-dark-1;
  }
}
