.corseInfo__head {
  &Box {
    display: flex;
    flex-direction: column;
  }
  &__navBox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 3rem;
    margin-right: 8rem;
    // @include respond(phone) {
    //   margin-right: 5.5rem;
    // }
  }

  &Red-dot {
    border-radius: 50%;
    width: 0.65rem;
    height: 0.65rem;
    background-color: $color-green1;
    margin-left: 1rem;
    // @include respond(phone) {
    //   width: 0.5rem;
    //   height: 0.5rem;
    // }
  }
  &TitleBox {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    & > :not(:first-child) {
      margin-right: 2rem;
      // @include respond(phone) {
      //   margin-right: 1rem;
      // }

    }
    & > :first-child::before,
    & > :nth-child(2)::before {
      content: "\2039";
      position: absolute;
      top: 8px;
      right: 23px;
      line-height: 0;
      font-size: 30px;
      font-weight: normal;
    
      @include respond(big-desktop) {
        right: 37px;
        font-size: 40px;
    top: 13px;
      }  
    }
    & > :nth-child(2)::before {
      right: 68px;
      // @include respond(phone) {
      //   right: 4.8rem;
      // }
      @include respond(big-desktop) {
        right: 113px;
    top: 13px;
      }
    }
  }
  &Title {
    list-style: none;
    a{
      color: #000;
      &:hover{
        color:#43aa8b;
      }
    }
  }
}
.corseInfo__head__contentBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  // @include respond(phone) {
  //   margin-right: 15rem;
  //   // margin: 0 auto;
  // }
}
.corseInfo__javaico {
  width: 12.7rem;
  height: 12.7rem;
  // @include respond(phone) {
  //   width: 8.7rem;
  //   height: 8.7rem;
  // }
}
.corseInfo__head__titleBox {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  align-items: flex-end;
  // @include respond(phone) {
  //   margin-right: 2rem;
  // }
}
.corseInfo__head__title {
  font-size: 3rem;
  font-weight: 400;
  // @include respond(phone) {
  //   font-size: 2rem;
  //   font-weight: 300;
  // }
}
.corseInfo__head__titre {
  border: none;
  color: $color-white;
  font-size: 3.5rem;
  padding: 0.25rem 3rem 1rem 3rem;
  background-color: $color-black-light;
  border-radius: 5rem;
  margin-top: 1.5rem;
  &:focus {
    outline: none;
  }
  // @include respond(phone) {
  //   font-size: 2.5rem;
  //   padding: 0.25rem 2rem 0.8rem 2rem;
  //   margin-top: 1rem;
  // }
}

.corseInfo__head__propertyTitle {
  font-size: 1.5rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  line-height: 0;
  justify-content: center;
  // @include respond(phone) {
  //   font-size: 1rem;
  // }
}

.stroke {
  stroke: $color-yellow;
  stroke-dasharray: 5;
}
.corseInfo__head__propertyBox {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.corseInfo__head__propertyContent-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.3rem;
  margin-bottom: 3rem;
  margin-right: 2rem;
  direction: rtl;
  .GridRows:not(.disable) {
    font-size: 1.3rem;
    justify-content: end;
    flex-direction: row;
}
  // @include respond(phone) {
  //   margin-right: 10rem;
  // }
}
.corseInfo__head__firstLine {
  width: 40%;
  height: 5rem;
  // @include respond(phone) {
  //   width: 36.5rem;
  //   height: 5rem;
  // }
}
.corseInfo__head__SecLine {
  width: 40%;
  height: 5rem;
  // @include respond(phone) {
  //   width: 35rem;
  //   height: 5rem;
  // }
}
