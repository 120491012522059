.viedeo__box {
  width: 50.6rem;
  height: 20rem;
  border-radius: 3rem;
  background-color: $color-pink-light;
  margin: 3rem auto;
  position: relative;
}

.video-react.video-react-fluid {
  width: 80rem;
  height: 5rem;
  &:focus {
    outline: none;
  }
}
.video-react .video-react-loading-spinner {
  display: none;
}
.video-react .video-react-video {
  width: 100%;
  background-color: #ffffff;

  overflow: hidden;
  &:focus {
    outline: none;
  }
}
.CorseIntro__video {
  border-radius: 3rem;
  // margin-left: 3.5rem;
  margin-top: 3.4rem;
  height: 5rem;
  background-color: $color-grey-dark-1;
}
.CorseIntro__video-Title {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: -13px;
  right: 0;
  padding: 2px 11px;
  width: 100%;
  background: #43aa8b;
  text-align: end;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: none;
  p {
    color: #ffffff;
    margin-right: 0;
  }
  span {
    margin: 0 2px;
  }

  // @include respond(phone) {
  //   font-size: 0.9rem;
  //   right: 30rem;
  // }
  &::before {
    content: '';
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: $color-white;
    top: 18px;
    right: 3px;
  }
}
.video-react .video-react-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 30px;
  border: 1px solid #fdfdfd;
  margin-bottom: 28px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}
.CorseIntro__videoheader-Title {
  font-size: 1.2rem;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  z-index: 5;
  top: 0px;
  right: 0;
  padding: 7px 15px;
  width: 100%;
  background: #43aa8b;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: none;
  p {
    color: #ffffff;
    margin-right: 0;
  }
  span {
    margin: 0 2px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0.7rem;
    height: 0.7rem;
    border-radius: 50%;
    background-color: #ffffff;
    top: 14px;
    right: 5px;
  }
}
.Intro__contentTxt {
  color: $color-black-light;
  font-size: 1.2rem;
  float: left;
  text-align: right;
  padding: 0 3.5rem 0px 5rem;
  width: 99%;
  line-height: 2.3rem;
  margin: 3rem auto 0 auto;

  // @include respond(phone) {
  //   padding: 0 11.5rem 0px 2rem;
  //   font-size: 0.9rem;
  //   width: 75%;
  // }
  & > * {
    border-right: 0.3rem solid $color-yellow;
    margin: 1rem 0;
    padding-right: 1rem;
  }
}
.CorseIntroPlayerBox {
  text-align: center;
  position: relative;
  height: 100%;
  box-shadow: 0 0 5px #dad7d7;
  // margin-left: 5rem;
  overflow: hidden;
   border-right: 30px;
  width: 90%;
  margin: 0 auto;
  border-radius: 3rem;
  // @include respond(phone) {
  //   margin-left: 10rem;
  // }
}
.befor-arrow {
  &::before {
    content: '<';
    margin-right: 5px;
    font-size: 15px;
    position: relative;
    top: 2px;
  }
}
button.video-react-big-play-button.video-react-big-play-button-left {
  top: 62px!important;
}
.courseinfo__background{
   display: grid;
    grid-template-columns: 1.3fr 4fr;
    margin-top: 81px;
    .CourseInfoContentBody_Box{
      grid-column: 2/3;
     // margin-right: 80px;
    }
}