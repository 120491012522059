.Content__videoLoader {
  border-radius: 3rem;
  margin-top: 3rem;
  position: relative;
  box-shadow: 0 0 5px #dad7d7;
}

.coursecontentVideoBox {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: absolute;
  top: 1rem;
  right: 2%;
}

.white__dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: $color-white;
  margin: 0 1rem;
}

.coursecontent__video__course,
.coursecontent__video__seson,
.coursecontent__video__lesson {
  color: white;
  font-size: 1.255rem;
  margin-left: 2rem;
  position: relative;
}
.coursecontent__video__course::after,
.coursecontent__video__seson::after {
  content: '<';
  position: absolute;
  font-size: 2rem;
  color: $color-white;
  right: -1.65rem;
  top: -0.3rem;
}
.codcontentEditor__end {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.editorShowBox1 {
  color: $color-pink-dark;
  display: flex;
  align-items: center;
}
.coursecontent__BtnBox {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2.5rem;
  margin-bottom: 5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $color-grey-light-4;
  // width: 110%;
}
.quiz-btn {
  padding: 0.9rem 2rem;
  font-size: 2rem;
  &:focus {
    outline: none;
  }
}
.editor-btn {
  font-size: 2rem;
  display: flex;
  align-items: center;
  padding: 0.9rem 1rem;
  margin-right: 2rem;
  .fas {
    margin-right: 0.4rem;
    font-size: 1.5rem;
  }
  &:focus {
    outline: none;
  }
}
.coursecontent__EndLine {
  background-color: $color-grey-light-4;
  height: 1px;
  width: 67%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  margin-left: 5rem;
}
.Content__BtnBox {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}
.Content__Btn {
  padding: 0.5rem 4rem;
  justify-content: right;
  &-dis {
    background: $color-grey-dark-1;
    padding: 0.5rem 4rem;
    cursor: not-allowed;
    &:hover,
    &:hover::after {
      transform: none;
      transition: none;
    }
  }
  &-dis1 {
    background: $color-grey-dark-1;
    color: white;
    border: none;
    padding: 0.5rem 2rem;
    margin-right: 2rem;
    cursor: not-allowed;
    &:hover {
      transform: none;
    }
  }
}
.Content__Btn1 {
  padding: 0.5rem 2rem;
  justify-content: right;
  margin-right: 2rem;
}

.CourseSeasonModal__background {
  background: #9c9c9c9f;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  .CourseSeasonModal__container {
    background-color: #ffffff;
    border-radius: 5px;
    width: 50vw;
    min-height: 50vh;
    position: relative;
  }
}
.CourseSeasonModal__list {
  padding: 30px 20px;
  text-align: right;
  .CourseSeasonModal__li {
    display: flex;
    text-align: right;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 15px;
    padding: 10px 0;
  }
}
.CourseSeasonModal__close {
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
  i {
    font-size: 15px;
  }
}
.CourseContentNav{
  @include respond(tab-port) {
    width: 80%;
    margin: 0 auto;
  }
}
.contentMain {
  @include respond(tab-port) {
    width: 90%;
    margin: 0 auto;
  }
}
