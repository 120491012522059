.header_topMargin {
  padding: 4rem;
}

.content__position {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 50px;
}
.feature__pos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  grid-template-rows: max-content;
  @include respond(small-phone) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &>*{
      margin-bottom: 2rem;
    }
  }
}
