.h-1 {
  font-size: 5rem;
  font-family: inherit;
  @include respond(phone) {
    font-size: 3rem;
  }
  @include respond(small-phone) {
    font-size: 2rem;
  }
  @include respond(tab-land) {
    text-align: end;
  }
}
.h-2 {
  font-size: 2rem;
  font-family: inherit;
  
}
.h-3 {
  font-size: 3.2rem;
  font-weight: 300;
  
}
.h-4 {
  font-size: 2.2rem;
  font-weight: normal;
}
.heading {
  flex-direction: column;

  img {
    width: 2.5rem;
    margin-bottom: 1.6rem;
  }
}
