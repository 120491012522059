.PhoneCourseHead {
  &__head-Box {
    display: flex;
    flex-direction: column;
  }
  &__head__navBox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-bottom: 3rem;
    margin-right: 2rem;
    margin-top: 7rem;
  }

  &__headRed-dot {
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    background-color: $color-green1;
    margin-left: 1rem;
  }
  &__headTitleBox {
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    & > :not(:first-child) {
      margin-right: 1rem;
    }
    & > :first-child::before,
    & > :nth-child(2)::before {
      content: '\2039';
      position: absolute;
      top: 7px;
    right: 15px;
      line-height: 0;
      font-size: 1.5rem;
      font-weight: normal;
    }
    & > :nth-child(2)::before {
      right: 46px;
    }
  }
  &__headTitle {
    list-style: none;
  }
}
.PhoneCourseHead__head__contentBox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  //   margin-right: 15rem;
}
.PhoneCourseHead__javaico {
  width: 8.7rem;
  height: 8.7rem;
}
.PhoneCourseHead__head__titleBox {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  align-items: flex-end;
}
.PhoneCourseHead__head__title {
  font-size: 2rem;
  font-weight: 300;
}
.PhoneCourseHead__head__titre {
  border: none;
  color: $color-white;
  font-size: 2.5rem;
  padding: 0.25rem 2rem 0.8rem 2rem;
  background-color: $color-black-light;
  border-radius: 5rem;
  margin-top: 1rem;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

.PhoneCourseHead__head__propertyTitle {
  font-size: 2rem;
  font-weight: normal;
  text-align: center;
  display: flex;
  line-height: 0;
  justify-content: center;
  @include respond(smallest-phone) {
    font-size: 1.5rem;
  }
}

.stroke {
  stroke: $color-yellow;
  stroke-dasharray: 5;
}
.PhoneCourseHead__head__propertyBox {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.PhoneCourseHead__head__propertyContent-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3.3rem;
  margin-bottom: 3rem;
  @include respond(smallest-phone) {
    margin-top: 1.3rem;
  }
}
.PhoneCourseHead__head__firstLine {
  width: 33%;
  height: 5rem;
  @include respond(smallest-phone) {
    width: 40%;
    height: 11rem;
  }
}

.PhoneCourseHead__head__SecLine {
  width: 33%;
  height: 5rem;
  @include respond(smallest-phone) {
    width: 40%;
    height: 11rem;
  }
}
