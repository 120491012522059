.Addtocard-dialog {
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  display: flex;
  background-color: $color-black-light;
  padding: 2rem 2rem;
  flex-direction: row-reverse;
  color: $color-white;
  font-size: 1.8rem;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  z-index: 9999;
  .fas {
    margin-top: 0.5rem;
  
  }
  button {
    background-color: transparent;
    outline: none;
    border: none;
    color: $color-white;
    margin-right: 0.9rem;
    cursor: pointer;
    &:focus {
      outline: none;
      border: none;
    }
  }
  .perview {
    // border-bottom: 1px solid #fff;
    color: $color-orange-dark-1;
    font-size: 1.8rem;
    font-weight: 700;
  }
}

.perview-box {
  position: fixed;
  bottom: 60px;
  left: 10px;
  height: auto;
  padding-bottom: 1rem;
  z-index: 9999;

  // background-color: $color-orange-dark-1;
  width: auto;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
}
.perview-course {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  flex: none;
  font-size: 1.4rem;
  z-index: 9999;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
    .fas {
      color: $color-orange-dark;
      margin-left: 0.8rem;
    }
  }
  &:not(:first-child) {
    padding: 1rem 1rem 1rem 1rem;
  }
  // padding: 1rem 1rem 0.7rem 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid $color-orange-dark-1;
  }
}
.perview-close-dialog {
  margin-left: 1rem;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-bottom: -1rem;
}
