.PhoneNavigationBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  position: fixed;
  z-index: 999999;
  width: 100vw;
  height: 7rem;
  top: 0;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  -moz-backdrop-filter: blur(10px);
}
.phonenavigation__logo {
  width: 15rem;
  height: auto;
  margin-top: 1rem;
  margin-right: 1rem;
}

.phoneNavigation {
  &__checkbox {
    display: none;
    // position: fixed;
    width: 6.5rem;
    height: 6.5rem;
    top: 1rem;
    left: 1rem;
    z-index: 3000;
  }

  &__botton {
    //background-color: $color-white;
    // filter: blur(0.2rem);
    width: 4rem;
    height: 4rem;
    position: fixed;
    top: 1.5rem;
    left: 1.5rem;
    border-radius: 50%;
    z-index: 2000;
   // box-shadow: 0 0.5rem 3rem rgba($color-black, 0.3);
    text-align: center;
    cursor: pointer;

    // @include respond(tab-port) {
    //   top: 4rem;
    //   right: 4rem;
    // }
    // @include respond(phone) {
    //   top: 3rem;
    //   right: 3rem;
    // }
  }

  //   &__background {
  //     height: 6rem;
  //     width: 6rem;
  //     border-radius: 50%;
  //     position: fixed;
  //     top: 4.5rem;
  //     left: 6.5em;
  //     // background-image: radial-gradient(
  //     //   $color-primary-light,
  //     //   $color-primary-dark
  //     // );
  //     z-index: 1000;
  //     transition: transform 0.8s cubic-bezier(0.83, 0, 0.17, 1);

  //     // @include respond(tab-port) {
  //     //   top: 4.5rem;
  //     //   right: 4.5rem;
  //     // }
  //     // @include respond(phone) {
  //     //   top: 3.5rem;
  //     //   right: 3.5rem;
  //     // }
  //   }

  &__nav {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1500;
    opacity: 0;
    width: 0;
    transition: all 0.8s cubic-bezier(0.68, -0.6, 0.32, 1.6);
  }

  &__list {
    position: absolute;
    top: 0;
    left: 0;
    list-style: none;
    text-align: center;
    width: 58%;
    display: flex;
    height: 100vh;
    flex-direction: column;
    background: #2b333fb3;
    backdrop-filter: blur(46px);
    align-items: center;
    justify-content: center;
  }

  &__item {
    // margin: 1rem;
    & > * {
      margin-left: 1rem;
    }
    &:first-child {
      margin-left: 1rem;
    }
  }

  &__link {
    &:link,
    &:visited {
      display: inline-block;
      font-size: 1.6rem;
      font-weight: 300;
      text-decoration: none;
      color: #ffffff;
      text-transform: uppercase;
      transition: all 0.4s;
      margin: 10px 0;
      span {
        margin-right: 1.5rem;
        display: inline-block;
      }
    }

    &:hover,
    &:active {
      background-position: 100%;
      color: $color-orange-dark-1;
      transform: translateX(1rem);
    }
  }

  &__checkbox:checked ~ &__background {
    transform: scale(80);
  }

  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
  }

  &__icon {
    position: relative;
    margin-top: 1.9rem;
    &,
    &::before,
    &::after {
      width: 2rem;
      height: 2px;
      // background-color: $color-grey-dark1;
      background: linear-gradient($color-yellow 10%, $color-orange-dark-1);
      display: inline-block;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      transition: all 0.2s;
    }
    &::before {
      top: -0.6rem;
    }
    &::after {
      top: 0.6rem;
    }
  }

  &__botton:hover &__icon::before {
    top: -1rem;
  }
  &__botton:hover &__icon::after {
    top: 1rem;
  }
  &__checkbox:checked + &__botton &__icon {
    background-color: transparent;
    background: transparent;
  }
  &__checkbox:checked + &__botton &__icon::before {
    top: 0;
    transform: rotate(135deg);
  }
  &__checkbox:checked + &__botton &__icon::after {
    top: 0;
    transform: rotate(-135deg);
  }
}
.Phonesignup-btn {
  padding: 0.25rem 1rem;
  font-size: 1.2rem !important;
  width: 60px;
  // margin-left: 0.8rem;
}
.phonenav__btn {
  border: 1px solid $color-green-dark-2;
  background-color: $color-white;
  margin-left: 0.8rem;
  border-radius: 1.8rem;
  padding: 0.2rem 2rem !important;
  font-size: 1.2rem !important;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  padding: 0.5rem 0.5rem;

  &:focus {
    outline: none;
  }
}
.phonesign-up {
  color: $color-green-dark-2;
  width: 60px;
  &:hover {
    background-color: $color-green-dark-2;
    color: $color-white;
  }
}
.Phonenav__teacher {
  margin: 0 0.8rem;
  color: $color-green-dark-2;
   font-size: 1.6rem;
  padding: 0 0.5rem;

  &:link,
  &:hover {
    color: $color-grey-dark;
  }
}
