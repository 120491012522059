.properties__Subtxt {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .PishNiazItem__Sub-List {
    margin-right: 4rem;
    direction: rtl;
    list-style-type: disc;
    font-size: 1.2rem;
  }
}
.PishNiazItem {
  margin-bottom: 1.5rem;
}
.PishNiazItem__sub {
  margin-bottom: 1.2rem;
}
