//-----------------black-------------------
$color-black: #202124;
$color-black-dark: #323232;
$color-black-dark-1: #1b1b1b;
$color-black-dark-2: #233d4d;
$color-black-light: #343638;
$color-black-light-1: #3436387a;

//------------------white-----------------
$color-white: #ffffff;
//--------------------gray-----------------------
$color-grey-dark: #a6adb4;
$color-grey-dark1: #989898;
$color-grey-dark-1: #707070;
$color-grey-light-1: #ebeded;
$color-grey-light-2: #f6f8f9;
$color-grey-light-3: #cbd0d3;
$color-grey-light-4: #656565;
$color-grey-light-5: #cccccc;
$color-gray-back: #fafafa;
//--------------------------blue--------------------
$color-blue: #72acff;
$color-blue-light: #86b7c8;
//---------------------green--------------
$color-green1: #0dca78;
$color-green: #52e5ba;
$color-green-dark-1: #90be6d;
$color-green-dark: #6ac24f;
$color-green-dark-2: #43aa8b;
$color-green-light: #c5e1a5;
//--------------------------orange----------------
$color-orange: #ffc96c;
$color-orange-dark-1: #fe7f2d;
$color-orange-dark: #f3722c;
$color-orange-light: #fdceaa;
$color-orange-light-1: #f3d2b873;
//--------------------yellow-------------
$color-yellow: #fcca46;
$color-yellow-1: rgba(252, 203, 70, 0.3);
//---------------------------pink-------------------------
$color-pink-dark: #eb4960;
$color-pink-light: #f4837d;
//---------------------------------red------------------------
$color-red: #ff4d4d;
$color-red-light: #f50909;

// $color-pink: #43AA8B;
// $color-secondary:#90BE6D;
